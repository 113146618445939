import {
    Box,
    Button,
    ButtonBase,
    createTheme,
    List,
    ListItem,
    listItemAvatarClasses,
    ListSubheader,
    Paper,
    styled,
    ThemeProvider,
    Typography,
} from "@mui/material";
import { useScroll, motion } from "framer-motion/dist/framer-motion";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import create from "zustand";
import { useInView } from "react-intersection-observer";
import rcbeamHeroURL from "../../../assets/images/hero1.png";
import terrianHeroURL from "../../../assets/images/terrianHero.png";
import Fabrica from "../../fabrica";
import { controller } from "../../fabrica/ThreeCanvas";
import { SubContainer } from "../../fabrica/controllers/switcher";
import { Download } from "@mui/icons-material";
import graphRCBEAM from "../../fabrica/assets/graph-rcbeam.json";
// import { OptionsController } from "../../fabrica/controllers";
const darkTheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            light: "#F5F5F6",
            main: "#EA1C5F",
            gray: "#3D3D3D",
            dark: "#20212C",
        },
        secondary: {
            light: "#F5F5F6",
            main: "#1670B8",
            gray: "#86898B",
            dark: "#2F303F",
        },
    },
});

export const useDemoStore = create(set => ({
    inputs: undefined,
    fabricaRef: undefined,
    faricaResizer: () => {},
    run: () => {},
    loadGraph: () => {},
    setInputs: v => set({ inputs: v }),
}));

const RootDiv = styled(Box)(({ theme }) => ({
    position: "relative",
    height: "580vh",
    scrollSnapType: "y mandatory",
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
    zIndex: 2,
    position: "fixed",
    right: "8%",
    width: 350,
    boxShadow: theme.shadows[10],
    // backgroundColor: "grey.800",
    "@supports (backdrop-filter: blur(3px)) or (-webkit-backdrop-filter: blur(3px))":
        {
            WebkitBackdropFilter: "blur(3px) saturate(125%)",
            backdropFilter: "blur(3px) saturate(125%)",
            background: `${theme.palette.grey[800]}99`,
            border: "unset",
        },
    [theme.breakpoints.down("lg")]: {
        width: "50%",
        bottom: "10%",
        top: "unset !important",
        right: "25% !important",
    },
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        bottom: 0,
        top: "unset !important",
        right: "unset !important",
    },
}));

const StyledNoCodePaper = styled(Paper)(({ theme }) => ({
    zIndex: 2,
    position: "fixed",
    right: "8%",
    width: "40%",
    height: "40%",
    top: "35%",
    boxShadow: theme.shadows[10],
    // backgroundColor: "grey.800",
    "@supports (backdrop-filter: blur(3px)) or (-webkit-backdrop-filter: blur(3px))":
        {
            WebkitBackdropFilter: "blur(3px) saturate(125%)",
            backdropFilter: "blur(3px) saturate(125%)",
            background: `${theme.palette.grey[800]}99`,
            border: "unset",
        },
    [theme.breakpoints.down("lg")]: {
        width: "80%",
        bottom: "10%",
        top: "unset !important",
        right: "10% !important",
    },
    [theme.breakpoints.down("sm")]: {
        height: "30%",
        width: "100%",
        bottom: 0,
        top: "unset !important",
        right: "unset !important",
    },
}));
const StyledList = styled(List)({
    [`& .${listItemAvatarClasses.root}`]: {
        // maxHeight: 40,
        minWidth: 100,
    },
});
function InputWindow() {
    const inputs = useDemoStore(state => state.inputs);
    // useEffect(() => {
    // console.log(inputs);
    // }, [inputs]);
    // <SubContainer dataTree={}/>
    return (
        <ThemeProvider theme={darkTheme}>
            <StyledPaper
                component={motion.div}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2, delay: 0.3 }}
                sx={{
                    top: `calc(60% - ${56 * (inputs?.length ?? 0)}px)`,
                }}>
                {!!inputs && (
                    <StyledList dense>
                        <SubContainer
                            dataTree={inputs.map(v => {
                                v.value = v.properties;
                                v.display_key = v.title;
                                return [undefined, v];
                            })}
                        />
                        <ListItem>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => useDemoStore.getState().run()}>
                                Apply
                            </Button>
                        </ListItem>
                    </StyledList>
                )}
            </StyledPaper>
        </ThemeProvider>
    );
}
function PreventNoCode() {
    const [isPrevent, setPrevent] = useState(true);
    return isPrevent ? (
        <div
            style={{
                display: isPrevent ? "flex" : "none",
                position: "absolute",
                inset: 0,
                zIndex: 1,
                // display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
                padding: 16,
            }}>
            <Button
                fullWidth
                variant="contained"
                onClick={() => setPrevent(false)}>
                Edit
            </Button>
        </div>
    ) : (
        <div
            style={{
                position: "absolute",
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 1,
                padding: 16,
            }}>
            <Button
                fullWidth
                variant="contained"
                onClick={() => setPrevent(true)}>
                Apply
            </Button>
        </div>
    );
}
function NoCode() {
    const ref = useRef();
    useLayoutEffect(() => {
        useDemoStore.setState({ fabricaRef: ref });
        useDemoStore.getState().faricaResizer();
        return () => {
            useDemoStore.setState({ fabricaRef: undefined });
        };
    }, []);
    return (
        <ThemeProvider theme={darkTheme}>
            <StyledNoCodePaper
                component={motion.div}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2, delay: 0.3 }}>
                <PreventNoCode />
                <div
                    ref={ref}
                    style={{
                        position: "absolute",
                        inset: 0,
                        // opacity: 1,
                        // top: 20,
                        overflow: "hidden",
                    }}
                />
            </StyledNoCodePaper>
        </ThemeProvider>
    );
}
function Store() {
    // const ref = useRef();

    return (
        <ThemeProvider theme={darkTheme}>
            <StyledPaper
                component={motion.div}
                initial={{ y: 10, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ duration: 0.2, delay: 0.3 }}
                sx={{
                    top: `calc(50% - 70px)`,
                }}>
                <List>
                    <ListSubheader disableSticky>
                        Applications
                    </ListSubheader>
                    <ListItem>
                        <Paper
                            component={ButtonBase}
                            sx={{
                                position: "relative",
                                flex: 0.5,
                                height: 80,
                                marginRight: 1,
                            }}
                            onClick={() => {
                                useDemoStore.getState().loadGraph(graphRCBEAM);
                            }}>
                            <div
                                style={{
                                    position: "absolute",
                                    inset: 0,
                                    backgroundImage: `url(${rcbeamHeroURL})`,
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                    opacity: 0.3,
                                }}></div>
                            <Typography
                                sx={{ position: "relative" }}
                                variant="button">
                                @NEXIVIL / RCBEAM
                            </Typography>
                            <Download />
                        </Paper>
                        <Paper
                            component={ButtonBase}
                            sx={{
                                position: "relative",
                                flex: 0.5,
                                height: 80,
                                marginLeft: 1,
                            }}
                            onClick={() => {
                                useDemoStore.getState().loadGraph();
                            }}>
                            <div
                                style={{
                                    position: "absolute",
                                    inset: 0,
                                    backgroundImage: `url(${terrianHeroURL})`,
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                    opacity: 0.3,
                                }}></div>
                            <Typography
                                sx={{ position: "relative" }}
                                variant="button">
                                @NEXIVIL / TERRIAN
                            </Typography>
                            <Download />
                        </Paper>
                    </ListItem>
                    <ListItem>
                        <Button fullWidth variant="contained" onClick={()=>window.location.assign("https://x.nexivil.com/store")}>
                            Explore Store 
                        </Button>
                    </ListItem>
                </List>
            </StyledPaper>
        </ThemeProvider>
    );
}
function ScrollSpyComponent() {
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["end end", "start start"],
    });

    // const points = useMemo(() => {
    //   const inc = (1 / dividedNum).toFixed(3);
    //   const result = new Array(dividedNum);
    //   result[0] = 0;
    //   result[dividedNum - 1] = 1;
    //   for (let i = 1; i < dividedNum - 1; i++) {
    //     result[i] = result[i - 1] + inc;
    //   }
    //   return result;
    // }, [dividedNum]);

    useEffect(() => {
        return scrollYProgress.onChange(latest => {
            controller.dollyTo(35 * latest, true);
        });
    }, []);

    return (
        <div
            ref={ref}
            style={{
                // height: "100%",
                inset: 0,
                width: "100%",
                position: "absolute",
                zIndex: -1,
            }}
        />
    );
}

function Demo({ className }, ref) {
    const fabRef = useRef();
    const { ref: sumRef, inView: sumInView } = useInView({ threshold: 0.1 });
    const { ref: firstRef, inView: firstInView } = useInView({
        threshold: 0.1,
    });
    const { ref: secondRef, inView: secondInView } = useInView({
        threshold: 0.1,
    });
    const { ref: thirdRef, inView: thirdInView } = useInView({
        threshold: 0.1,
    });
    const { ref: fourthRef, inView: fourthInView } = useInView({
        threshold: 0.1,
    });

    useEffect(() => {
        if (sumInView) {
            fabRef.current?.setTitle(`Features`);
            fabRef.current?.setComp(<React.Fragment />);
        } else if (firstInView) {
            fabRef.current?.setTitle(`CUSTOMIZED \n APPLICATION`);
            fabRef.current?.setComp(<InputWindow />);
        } else if (secondInView) {
            fabRef.current?.setTitle("NO-CODE TOOL");
            fabRef.current?.setComp(<NoCode />);
        } else if (thirdInView) {
            fabRef.current?.setTitle("3rd Party Store");
            fabRef.current?.setComp(<Store />);
        } else if (fourthInView) {
            fabRef.current?.setTitle("");
            fabRef.current?.setComp(<React.Fragment />);
        } else {
            fabRef.current?.setComp(<React.Fragment />);
        }
        // if (thirdInView) fabRef.current?.setTitle("NODE STORES");
    }, [sumInView, firstInView, secondInView, thirdInView]);

    return (
        <RootDiv ref={ref} className={className}>
            <Fabrica ref={fabRef} />
            <div
                style={{
                    width: "100vw",
                    height: "100vh",
                    position: "absolute",
                    top: 0,
                    zIndex: -1,
                }}
                ref={sumRef}
            />
            <div
                style={{
                    width: "100vw",
                    height: "120vh",
                    position: "absolute",
                    top: "100vh",
                    zIndex: -1,
                }}
                ref={firstRef}
            />
            <div
                style={{
                    width: "100vw",
                    height: "120vh",
                    position: "absolute",
                    top: "220vh",
                    zIndex: -1,
                }}
                ref={secondRef}
            />
            <div
                style={{
                    width: "100vw",
                    height: "120vh",
                    position: "absolute",
                    top: "340vh",
                    zIndex: -1,
                }}
                ref={thirdRef}
            />
            <div
                style={{
                    width: "100vw",
                    height: "100vh",
                    position: "absolute",
                    top: "460vh",
                    zIndex: -1,
                }}
                ref={fourthRef}
            />
            <ScrollSpyComponent />
        </RootDiv>
    );
}

export default React.forwardRef(Demo);
