import React from "react";
import { useParams } from "react-router-dom";
import {
    Box,
    Button,
    Card,
    CardMedia,
    styled,
    Typography,
} from "@mui/material";
import { ReactComponent as MediumContentCopyright } from "../../../../assets/images/svg/MediumContentCopyright.svg";
// import { projectList } from "../../ShowcaseList/ShowcaseList";

const RootDiv = styled("div")(({ theme }) => ({
    [`& > .MuiBox-root`]: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        marginBottom: "42px",
        [theme.breakpoints.down("sm")]: {
            marginBottom: "24px",
        },
        [`& > .content-copyright-icon`]: {
            marginRight: "22px",
            [theme.breakpoints.down("sm")]: {
                marginRight: "10px",
            },
        },
        [`& > .keyword-wrapper`]: {
            display: "flex",
            alignItems: "center",
            [`& .keyword`]: {
                backgroundColor: theme.palette.background.white,
                border: "0.5px solid #86898B",
                borderRadius: "22px",
                padding: "4px 20px",
                fontSize: "16px",
                fontWeight: theme.typography.fontWeightRegular,
                color: theme.palette.secondary.gray,
                lineHeight: "30px",
                marginRight: "4px",
                [theme.breakpoints.down("sm")]: {
                    fontSize: "14px",
                    lineHeight: "26px",
                    padding: "2.5px 16.5px",
                },
            },
        },
    },
    [`& > .MuiCard-root`]: {
        width: "100%",
        height: "100%",
        borderRadius: "8px",
        marginBottom: "60px",
        [theme.breakpoints.down("lg")]: {
            marginBottom: "34px",
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom: "24px",
        },
        [`& > .MuiCardMedia-root`]: {
            height: "760px",
            [theme.breakpoints.down("lg")]: {
                height: "580px",
            },
            [theme.breakpoints.down("sm")]: {
                height: "380px",
            },
        },
    },
    [`& > .description-wrapper`]: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "24px 13px",
        marginBottom: "64px",
        [theme.breakpoints.down("lg")]: {
            padding: "10px 14px",
            marginBottom: "46px",
        },
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            padding: 0,
        },
        [`& > .MuiTypography-root`]: {
            maxWidth: "838px",
            color: theme.palette.primary.dark,
            [theme.breakpoints.down("lg")]: {
                fontSize: "16px",
                maxWidth: "510px",
                lineHeight: "24px",
            },
            [theme.breakpoints.down("sm")]: {
                fontSize: "14px",
                lineHeight: "20px",
                marginBottom: "24px",
            },
        },
        [`& > .MuiButton-root`]: {
            padding: "12px 36px",
            border: "1px solid #20212c",
            borderRadius: "8px",
            color: theme.palette.primary.dark,
            fontSize: theme.typography.fontSize,
            fontWeight: theme.typography.fontWeightBold,
            lineHeight: "17px",
            textTransform: "capitalize",
            [theme.breakpoints.down("lg")]: {
                fontSize: "16px",
                lineHeight: "17px",
                padding: "10px 35px",
            },
            [theme.breakpoints.down("sm")]: {
                fontSize: "12px",
                lineHeight: "14px",
                padding: "10px 35px",
            },
            [`&:hover`]: {
                border: "1px solid #EA1C5F",
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.text.secondary,
                transition: "all 0.5s ease-in-out",
            },
        },
    },
}));

function DetailProject() {
    const { id } = useParams();
    // const project = projectList.filter(item => item.id === Number(id));

    return (
        <RootDiv>
            <Box>
                <MediumContentCopyright className="content-copyright-icon" />
                <Box className="keyword-wrapper">
                    {/* <span className="keyword one">{project[0].keyword[0]}</span>
                    <span className="keyword two">{project[0].keyword[1]}</span>
                    <span className="keyword three">
                        {project[0].keyword[2]}
                    </span> */}
                </Box>
            </Box>
            <Card>
                <CardMedia
                    component="img"
                    // image={project[0].thumbnailUrl}
                    alt="showcase thumbnail"
                />
            </Card>
            <Box className="description-wrapper">
                {/* <Typography variant="body3">{project[0].desc}</Typography> */}
                <Button
                    variant="outlined"
                    component="button"
                    disableFocusRipple
                    disableRipple
                    href="https://x.nexivil.com/"
                    target="_blank"
                    rel="noopener">
                    Open X
                </Button>
            </Box>
        </RootDiv>
    );
}

export default DetailProject;
