import React, { useLayoutEffect, useRef } from "react";
import * as THREE from "three";
// import FBXLoader from "three/examples/jsm/loaders/FBXLoader";
import CameraControls from "camera-controls";

let SCREEN_WIDTH = window.innerWidth;
let SCREEN_HEIGHT = window.innerHeight - 100;
let aspect = SCREEN_WIDTH / SCREEN_HEIGHT;

let camera, renderer, mesh;
// let cameraRig;
let cameraPerspective;
// let cameraPerspectiveHelper;
export let controller;
// const frustumSize = 600;
const clock = new THREE.Clock(true);
export const memoryAddress = { scene: undefined };

function init(canvasContainer) {
  CameraControls.install({ THREE: THREE });

  memoryAddress.scene = new THREE.Scene();
  memoryAddress.scene.background = new THREE.Color(0x1e1f24);

  //

  camera = new THREE.PerspectiveCamera(30, 1, 1, 1.7976931348623157e8);
  camera.position.set(0, 0, 1);

  renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(SCREEN_WIDTH, SCREEN_HEIGHT);

  // renderer.autoClear = false;

  canvasContainer.appendChild(renderer.domElement);

  // add controller

  controller = new CameraControls(camera, renderer.domElement);
  controller.mouseButtons.right = CameraControls.ACTION.OFFSET;
  controller.dollyTo(35);
  // controller.rotatePolarTo(50);
  // controller.rotateAzimuthTo(50);
  controller.truck(0, -5);

  // controller.minDistance = 1;
  // controller.infinityDolly = true;
  // controller.dampingFactor = 1;
  // controller.draggingDampingFactor = 1;

  // controller.maxPolarAngle = Math.PI;
  // controller.minAzimuthAngle = -Infinity;

  const gridHelper = new THREE.GridHelper(50, 50);
  gridHelper.position.y = -1;
  memoryAddress.scene.add(gridHelper);

  //test
  const mesh = new THREE.Mesh(
    new THREE.BoxGeometry(1, 1, 1),
    new THREE.MeshBasicMaterial({ color: 0xff0000, wireframe: true })
  );
  memoryAddress.scene.add(mesh);

  renderer.render(memoryAddress.scene, camera);
}

//

function onWindowResize() {
  SCREEN_WIDTH = window.innerWidth;
  SCREEN_HEIGHT = window.innerHeight - 100;
  aspect = SCREEN_WIDTH / SCREEN_HEIGHT;

  renderer.setSize(SCREEN_WIDTH, SCREEN_HEIGHT);

  camera.aspect = aspect;
  camera.updateProjectionMatrix();

  // cameraPerspective.aspect = 0.5 * aspect;
  // cameraPerspective.updateProjectionMatrix();
}

//

function animate() {
  const delta = clock.getDelta();
  const elapsed = clock.getElapsedTime();
  const updated = controller.update(delta);

  // if ( elapsed > 30 ) { return; }

  requestAnimationFrame(animate);

  if (updated) {
    renderer.render(memoryAddress.scene, camera);
  }
}

// function render() {
//     cameraRig.lookAt(mesh.position);

//     renderer.clear();

//     cameraPerspectiveHelper.visible = false;

//     renderer.setViewport(0, 0, SCREEN_WIDTH / 2, SCREEN_HEIGHT);
//     renderer.render(scene, cameraPerspective);

//     cameraPerspectiveHelper.visible = true;

//     renderer.setViewport(SCREEN_WIDTH / 2, 0, SCREEN_WIDTH / 2, SCREEN_HEIGHT);
//     renderer.render(scene, camera);
// }

export const THREECanvas = React.memo(
  function THREECanvas() {
    const canvasRef = useRef();
    useLayoutEffect(() => {
      init(canvasRef.current);
      window.addEventListener("resize", onWindowResize);
      onWindowResize();
      animate();
      return () => window.removeEventListener("resize", onWindowResize);
    }, []);
    return (
      <div
        ref={canvasRef}
        style={{ inset: "0 0 0 0", position: "absolute" }}
      ></div>
    );
  },
  () => true
);
