import React, { useEffect, useState } from "react";
import { Button, buttonClasses, Grid, styled, Typography } from "@mui/material";
import ButtonDivGen from "../ButtonAnimation/ButtonDivGen";
import ShowcaseListItem from "./ShowcaseList/ShowcaseListItem/ShowcaseListItem";

const RootDiv = styled("div")(({ theme }) => ({
    maxWidth: "1198px",
    margin: "0 auto",
    paddingTop: "150px",
    [theme.breakpoints.down("lg")]: {
        padding: "18px 16px",
        paddingTop: "100px",
    },
    [`& > .page-name`]: {
        color: theme.palette.secondary.gray,
        marginBottom: "32px",
        [theme.breakpoints.down("lg")]: {
            fontSize: "20px",
        },
    },
    [`& > .title-wrapper`]: {
        marginBottom: "8px",
        [`& > .title`]: {
            maxWidth: "710px",
            color: theme.palette.secondary.dark,
            textAlign: "center",
            margin: "0 auto",
            [theme.breakpoints.down("lg")]: {
                width: "590px",
                fontSize: "50px",
                fontWeight: theme.typography.fontWeightExtraBold,
                lineHeight: "62px",
            },
            [theme.breakpoints.down("sm")]: {
                width: "312px",
                fontSize: "28px",
            },
        },
    },
    [`& > .subtitle`]: {
        // @TODO fontWeight가 theme에 적용된걸 반영하지못합니다. why??
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.secondary.gray,
        textAlign: "center",
        marginBottom: "80px",
        [theme.breakpoints.down("lg")]: {
            fontSize: "18px",
            lineHeight: "20px",
            letterSpacing: "0.07em",
            marginBottom: "46px",
        },
    },
    [`& > .${buttonClasses.root}`]: {
        display: "block",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "30px",
        padding: "8px 48px",
        margin: "0 auto",
        marginBottom: "128px",
        fontWeight: theme.typography.fontWeightBold,
        fontSize: "24px",
        lineHeight: "23px",
        letterSpacing: "0em",
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.black,
        textTransform: "capitalize",
        transform: "translateY(-4px) translateZ(0)",
        transition: "transform .44s ease, boxShadow .44s ease",
        transitionDuration: ".5s",
        trnasitionProperty: "transform",
        boxShadow: "0 6px 6px -1px #0e0f1452",
        [`& > div`]: {
            display: "flex",
            overflow: "hidden",
            textShadow: "0 28px 0 #fff",
            [`& > span`]: {
                display: "block",
                backfaceVisibility: "hidden",
                fontStyle: "normal",
                transition: "transform .44s ease",
                [`&:nth-of-type(1)`]: { transitionDelay: ".05s" },
                [`&:nth-of-type(2)`]: { transitionDelay: ".1s" },
                [`&:nth-of-type(3)`]: { transitionDelay: ".15s" },
                [`&:nth-of-type(4)`]: { transitionDelay: ".2s" },
                [`&:nth-of-type(5)`]: { transitionDelay: ".25s" },
                [`&:nth-of-type(6)`]: { transitionDelay: ".3s" },
                [`&:nth-of-type(7)`]: { transitionDelay: ".35s" },
                [`&:nth-of-type(8)`]: { transitionDelay: ".4s" },
                [`&:nth-of-type(9)`]: { transitionDelay: ".45s" },
                [`&:nth-of-type(10)`]: { transitionDelay: ".5s" },
                [`&:nth-of-type(11)`]: { transitionDelay: ".55s" },
                [`&:nth-of-type(12)`]: { transitionDelay: ".6s" },
                [`&:nth-of-type(13)`]: { transitionDelay: ".65s" },
                [`&:nth-of-type(14)`]: { transitionDelay: ".7s" },
                [`&:nth-of-type(15)`]: { transitionDelay: ".75s" },
            },
        },
        [`&:hover`]: {
            shadow: "0 4px 20px -2px #00000052",
            boxShadow: "0 6px 6px #000",
            transform: "translateY(-8px)",
            [`& > div`]: {
                [`& > span`]: {
                    transform: `translateY(-28px) translateZ(0)`,
                },
            },
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: "22px",
            lineHeight: "24px",
            padding: "8px 44px",
            marginTop: "51px",
            marginBottom: "76px",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "16px",
            lineHeight: "18px",
            marginTop: "48px",
            marginBottom: "70px",
        },
    },
}));

function Showcase() {
    const [projectLists, setProjectLists] = useState([]);

    useEffect(() => {
        const getData = async () => {
            const response = await fetch("/data/project.json");
            const data = await response.json();
            setProjectLists(data);
        };
        getData();
    }, []);

    return (
        <RootDiv>
            <Typography variant="subtitle2" className="page-name">
                Showcase
            </Typography>
            <div className="title-wrapper">
                <Typography variant="h2" className="title">
                    Explore
                </Typography>
                <Typography variant="h2" className="title">
                    the popularity project
                </Typography>
            </div>
            <Typography variant="h5" className="subtitle">
                You can share your design information with the authorized users.
            </Typography>
            <Grid container md={12} spacing={3}>
                {projectLists &&
                    projectLists.project?.map(item => (
                        <ShowcaseListItem
                            key={item.id}
                            id={item.id}
                            thumbnail={item.hero_img}
                            icon={item.icon}
                            keywords={item.keywords}
                        />
                    ))}
            </Grid>
            <Button
                variant="contained"
                component="button"
                disableFocusRipple
                disableRipple>
                <ButtonDivGen text="View More" />
            </Button>
        </RootDiv>
    );
}

export default Showcase;
