import React from "react";
import { useParams } from "react-router-dom";
import { Grid, styled, Typography } from "@mui/material";
import MoreProjectItem from "./MoreProjectItem/MoreProjectItem";

const RootDiv = styled("div")(({ theme }) => ({
    marginBottom: "180px",
    [theme.breakpoints.down("lg")]: {
        marginBottom: 0,
    },
    [`& > .MuiTypography-subtitle2`]: {
        color: theme.palette.secondary.gray,
        marginBottom: "32px",
        [theme.breakpoints.down("lg")]: {
            marginBottom: "24px",
            fontSize: "32px",
            fontWeight: theme.typography.fontWeightBold,
            lineHeight: "34px",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "20px",
            lineHeight: "48px",
        },
    },
}));

function MoreProject() {
    const { id } = useParams();
    // const moreProject = projectList.filter(item => item.id !== Number(id));

    return (
        <RootDiv>
            <Typography variant="subtitle2">More project</Typography>
            <Grid container md={12} spacing={3}>
                {/* {moreProject &&
                    moreProject.map(item => (
                        <MoreProjectItem
                            key={item.id}
                            id={item.id}
                            image={item.thumbnailUrl}
                            keyword={item.keyword}
                        />
                    ))} */}
            </Grid>
        </RootDiv>
    );
}

export default MoreProject;
