import React from "react";
import { Link } from "react-router-dom";
import { Box, Button, styled, Typography } from "@mui/material";

import Character from "../../assets/images/icecream_character.png";

const RootDiv = styled("div")(({ theme }) => ({
    maxWidth: "1198px",
    height: "100vh",
    margin: "0 auto",
    overflowX: "hidden",
    [`& > .MuiBox-root`]: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "1198px",
        height: "100%",
        margin: "0 auto",
        [`& > .typo-wrapper`]: {
            textAlign: "center",
            marginBottom: "68px",
            [`& > .MuiTypography-h1`]: {},
            [`& > .MuiTypography-h3`]: {
                textTransform: "uppercase",
            },
            [`& > .MuiTypography-h5`]: {
                // @TODO fontWeight가 theme에 적용된걸 반영하지못합니다. why??
                fontWeight: theme.typography.fontWeightRegular,
                color: theme.palette.secondary.gray,
            },
        },
        [`& > .img-wrapper`]: {
            width: "285px",
            height: "315px",
            margin: "0 auto",
            marginBottom: "68px",
        },
        [`& > .MuiButton-root`]: {
            backgroundColor: theme.palette.secondary.dark,
            margin: "0 auto",
            padding: "20px 40px",
            [`& > a`]: {
                color: theme.palette.primary.light,
                fontSize: "21px",
                fontWeight: 100,
                lineHeight: "23px",
                letterSpacing: "0.27rem",
                textDecoration: "none",
                textTransform: "capitalize",
            },
        },
    },
}));

function NotFound(props) {
    props.funcNav(false);

    return (
        <RootDiv>
            <Box>
                <div className="typo-wrapper">
                    <Typography variant="h1">OOPS!</Typography>
                    <Typography variant="h3">
                        We can't find that page.
                    </Typography>
                    <Typography variant="h5">
                        I'm melting. Please return to the Front Page
                    </Typography>
                </div>
                <div className="img-wrapper">
                    <img
                        // src="./icecream_character.png"
                        src={Character}
                        alt="404 page character"
                        title="404 page cahracter"
                    />
                </div>
                <Button variant="contained" disableFocusRipple disableRipple>
                    <Link to="/">Back To Home</Link>
                </Button>
            </Box>
        </RootDiv>
    );
}

export default NotFound;
