import * as THREE from 'three';
import * as THREELINE from 'meshline';

const sumFn = (acc, curr) => acc + curr


export function extrudeBufferGeometry(shapePts, length) {
    let shape = new THREE.Shape()
    let shapeVectors = []
    shapePts.forEach(pt => {
        let vec = new THREE.Vector3(pt.x, pt.y, pt.z)
        shapeVectors.push(vec)
    });
    shape.setFromPoints(shapeVectors)

    let geometry = new THREE.ExtrudeBufferGeometry(shape, { depth: length, steps: 1, bevelEnabled: false })
    return geometry
}

class pointsCurve extends THREE.Curve {
    constructor(pathPts, scale = 1) {
        super();
        this.scale = scale;
        this.pathPts = pathPts

        let interverArr = []
        for (let i = 0; i < pathPts.length - 1; i++) {
            let iEl = pathPts[i]
            let jEl = pathPts[i + 1]
            let x = jEl.x - iEl.x
            let y = jEl.y - iEl.y
            let z = jEl.z - iEl.z
            interverArr.push(Math.sqrt(x ** 2 + y ** 2 + z ** 2))
        }
        this.interverArr = interverArr
        this.pathLength = interverArr.reduce(sumFn)

        let lengthArr = []
        for (let i = 0; i < interverArr.length; i++) {
            let sum = 0
            for (let j = 0; j <= i; j++) {
                sum += interverArr[j]
            }
            lengthArr.push(sum)
        }
        this.lengthArr = lengthArr
    }

    getPoint(t, optionalTarget = new THREE.Vector3()) {
        let tLength = t * this.pathLength

        let index = 0
        for (let i = 0; i < this.lengthArr.length; i++) {
            let parentLength = this.lengthArr[i]
            if (tLength <= parentLength) {
                index = i
                break
            }
        }

        let tS = index === 0 ? tLength : tLength - this.lengthArr[index - 1]
        let parentS = this.interverArr[index]
        let ratio = tS / parentS

        const iPt = this.pathPts[index]
        const jPt = this.pathPts[index + 1]

        const tx = (jPt.x - iPt.x) * ratio
        const ty = (jPt.y - iPt.y) * ratio
        const tz = (jPt.z - iPt.z) * ratio

        return optionalTarget.set(tx, ty, tz).multiplyScalar(this.scale);
    }
}

export function tubeBufferGeometry(pathPts, positionCoord, radius) {

    let pts = []
    for (let i in pathPts) {
        let x = pathPts[i].x
        let y = pathPts[i].y
        let z = pathPts[i].z
        pts.push(new THREE.Vector3(x, y, z))
    }
    let path = new pointsCurve(pathPts)
    let geometry = new THREE.TubeBufferGeometry(path, 20, radius, 8, false)
    geometry.translate(positionCoord.x, positionCoord.y, positionCoord.z)
    return geometry
}

export function rebarGeometry(points) {
    let pts = [];
    try {
        for (let i in points) {
            let x = points[i].x
            let y = points[i].y
            let z = points[i].z
            if (!Number.isNaN(x + y + z))
                pts.push(
                    new THREE.Vector3(x, y, z)
                );
        }
    } catch (e) {
        console.log("rebarGeometryError, points: ", points);
    }
    let geometry = new THREELINE.MeshLine();
    // let geometry = new THREE.Linegeome();
    geometry.setPoints(pts);
    return geometry;
}

