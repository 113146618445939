import React from "react";
import { Checkbox, ListItem, ListItemAvatar, Typography, styled } from "@mui/material";

const Root = styled(ListItem)(({theme}) => ({
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
}))


function Main({v}) {
    let currentData = v.value[v.view_info.key] || false;

    function handleOnBlur(e) {
        currentData = e.target.checked;
        v.value[v.view_info.key] = currentData;
    }

    return (
        <Root>
            <ListItemAvatar>
                <Typography variant="overline">{v.display_key}</Typography>
            </ListItemAvatar>
            <Checkbox
                size="small"
                onBlur={handleOnBlur}
                // name={formName}
                defaultChecked={currentData}
                // disabled={disabled}
            />
        </Root>
    );
}

export default Main;
