import React from "react";
import { styled, Typography } from "@mui/material";
import DetailProject from "./DetailContents/DetailProject";
import MoreProject from "./MoreProject/MoreProject";

const RootDiv = styled("div")(({ theme }) => ({
    maxWidth: "1198px",
    margin: "0 auto",
    paddingTop: "150px",
    [theme.breakpoints.down("lg")]: {
        padding: "18px 16px 0",
        paddingTop: "100px",
    },
    [`& > .pageName`]: {
        fontSize: "36px",
        fontWeight: theme.typography.fontWeightBold,
        color: "#86898B",
        lineHeight: "48px",
        marginBottom: "32px",
        [theme.breakpoints.down("lg")]: {
            fontSize: "20px",
        },
    },
    [`& > .title`]: {
        fontSize: "64px",
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.secondary.dark,
        lineHeight: "88px",
        textAlign: "center",
        marginBottom: "4px",
        [theme.breakpoints.down("lg")]: {
            fontSize: "50px",
            lineHeight: "62px",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "32px",
            lineHeight: "43px",
            marginBottom: "8px",
        },
    },
    [`& > .subtitle`]: {
        fontSize: "18px",
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.secondary.gray,
        lineHeight: "20px",
        textAlign: "center",
        marginBottom: "80px",
        [theme.breakpoints.down("lg")]: {
            marginBottom: "52px",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
            marginBottom: "30px",
        },
    },
}));

function ShowcaseDetail() {
    return (
        <RootDiv>
            <Typography variant="subtitle2" className="pageName">
                Showcase
            </Typography>
            <Typography variant="h2" className="title">
                The popularity project
            </Typography>
            <Typography variant="h5" className="subtitle">
                Open and experience Design Express project.
            </Typography>
            <DetailProject />
            <MoreProject />
        </RootDiv>
    );
}

export default ShowcaseDetail;
