import React, { useEffect, useState } from "react";
import { ListItem, ListItemAvatar, styled, Typography } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { SubContainer } from "./switcher";

// import { useStore } from "..";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
}));
const StyledDiv = styled("div")(({ theme }) => ({
  borderLeft: `1px solid ${theme.palette.custom.grey[4]}`,
  marginLeft: theme.spacing(2),
}));

function _automaticTypeSelector(data) {
  if (!data ?? true) return { children: [], use_in: true };
  return {
    children: Object.entries(data).map(([k, v], i) => [
      `${k}.${i}`,
      {
        view_info: { type: typeof v, key: k, display_key: `${k}` },
        value: data,
        key: k,
        display_key: `${k}`,
      },
    ]),
    use_in: true,
  };
}

function _parseValidator(validator, data) {
  if (!data ?? true) return { children: [], use_in: true };
  return {
    children: Object.entries(validator).map(([k, v], i) => [
      `${k}.${i}`,
      {
        view_info: { ...v, key: k, display_key: `${k}` },
        value: data,
        key: k,
        display_key: `${v.title ? v.title : k}`,
      },
    ]),
    use_in: true,
  };
}

/**
 * @todo  will support memorizing, folder.js:84
 */
const InsideFolder = React.memo(
  function InsideFolder({ children, use_in, graphID, editor }) {
    // const list = getViewInputs()[graphID];
    const list = {};

    return children !== null && (use_in ?? false) ? (
      <SubContainer dataTree={children} editor={editor} />
    ) : (
      <SubContainer
        dataTree={children
          // .filter(v => list.has(v))
          .map((v) => [v, list.get(v)])}
        editor={editor}
      />
    );
  },
  (prev, next) => prev.graphID !== next.graphID
);

function Main({ v, graphID, editor }) {
  const { title, view_info, value, display_key = null } = v;
  const { key } = view_info;
  const { children, use_in = false } =
    view_info.type === "folder" && (view_info.children ?? false)
      ? view_info
      : !!view_info.validator ?? false
      ? _parseValidator(view_info.validator, value[key])
      : _automaticTypeSelector(value[key]);
  const [open, setOpen] = useState(false);

  function handleOnClick() {
    console.log("dbClick?");
    setOpen((v) => !v);
  }

  useEffect(() => {
    setOpen(false);
  }, [graphID]);

  return (
    <React.Fragment>
      <StyledListItem
        button
        onClick={handleOnClick}
        disabled={children.length < 1}
      >
        <ListItemAvatar>
          <Typography variant="overline">{display_key ?? title}</Typography>
        </ListItemAvatar>
        {open ? <ExpandLess /> : <ExpandMore />}
      </StyledListItem>
      {open && (
        <StyledDiv>
          <InsideFolder
            children={children}
            use_in={use_in}
            graphID={graphID}
            editor={editor}
          />
        </StyledDiv>
      )}
    </React.Fragment>
  );
}

export default Main;
