import React from "react";
import {
    Box,
    Container,
    IconButton,
    styled,
    Tooltip,
    Typography,
} from "@mui/material";

import { ReactComponent as LogoBlack } from "../../assets/images/svg/Logo_Black.svg";
import { ReactComponent as Mail } from "../../assets/images/svg/Mail.svg";
import { ReactComponent as Map } from "../../assets/images/svg/Map.svg";
import { ReactComponent as PDF } from "../../assets/images/svg/PDF_Icon.svg";

const RootBox = styled(Box)(({ theme }) => ({
    width: "100%",
    backgroundColor: theme.palette.background.footer,
    [`& > .MuiContainer-root`]: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "180px",
        color: theme.palette.text.secondary,
        [theme.breakpoints.down("lg")]: {
            height: "220px",
            flexDirection: "column",
            padding: "48px 227px 22px",
        },
        [theme.breakpoints.down("sm")]: {
            height: "248px",
            flexDirection: "column",
            padding: "25px 16px 27px",
        },
        [`& > .logo-slogan`]: {
            display: "flex",
            flexDirection: "row",
            [theme.breakpoints.down("lg")]: {
                flexDirection: "row",
            },
            [theme.breakpoints.down("sm")]: {
                flexDirection: "column",
            },
            [`& > svg`]: {
                margin: "0 auto",
                [theme.breakpoints.down("lg")]: {
                    marginRight: "10px",
                },
                [theme.breakpoints.down("sm")]: {
                    margin: "0 auto",
                },
            },
            [`& > .slogan`]: {
                color: theme.palette.secondary.gray,
                textAlign: "center",
                marginLeft: "32px",
                [theme.breakpoints.down("lg")]: {
                    fontSize: "16px",
                    lineHeight: "30px",
                    margin: 0,
                },
                [theme.breakpoints.down("sm")]: {
                    fontSize: "14px",
                    margin: "8px 0 28px",
                },
            },
        },
        [`& > .icon-group`]: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            [theme.breakpoints.down("lg")]: {
                marginBottom: 0,
            },
            [theme.breakpoints.down("sm")]: {
                marginBottom: "28px",
            },
            [`& > .MuiIconButton-root`]: {
                width: "36px",
                height: "36px",
                backgroundColor: theme.palette.text.secondary,
                borderRadius: "15px",
                marginRight: "32px",
                padding: "7px",
                [`&:last-child`]: {
                    marginRight: 0,
                },
                [`&:hover`]: {
                    backgroundColor: theme.palette.secondary.main,
                    transition: "all 0.5s ease-in-out",
                },
            },
            [`& .icon-first`]: {
                [`&:hover`]: {
                    [`& path`]: {
                        stroke: "white",
                        transition: "all 0.5s ease-in-out",
                        [`&:nth-of-type(2)`]: {
                            fill: "white",
                        },
                        [`&:last-child`]: {
                            fill: "white",
                        },
                    },
                },
            },
            [`& .icon-second`]: {
                [`&:hover`]: {
                    [`& path`]: {
                        stroke: "white",
                        transition: "all 0.5s ease-in-out",
                    },
                },
            },
            [`& .icon-third`]: {
                [`&:hover`]: {
                    [`& rect`]: {
                        stroke: "white",
                        transition: "all 0.5s ease-in-out",
                    },
                    [`& path`]: {
                        stroke: "white",
                        transition: "all 0.5s ease-in-out",
                    },
                },
            },
        },
        [`& > .copyright`]: {
            width: "322px",
            textAlign: "right",
            color: theme.palette.secondary.gray,
            [theme.breakpoints.down("lg")]: {
                fontSize: "16px",
                textAlign: "center",
            },
            [theme.breakpoints.down("sm")]: {
                fontSize: "14px",
            },
        },
    },
}));

function Footer() {
    return (
        <RootBox>
            <Container maxWidth="xl">
                <div className="logo-slogan">
                    <LogoBlack className="footer-logo" />
                    <Typography variant="body5" className="slogan">
                        To the Next Civilization
                    </Typography>
                </div>
                <div className="icon-group">
                    <Tooltip title="Company Profile" arrow="true">
                        <IconButton
                            className="icon-first"
                            href="https://www.nexivil.com/media/public/dx_promote.pdf"
                            target="_blank"
                            rel="noopener">
                            <PDF />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Map" arrow="true">
                        <IconButton
                            className="icon-second"
                            href="https://goo.gl/maps/dnXS2Bz1fPyXFRhm9"
                            target="_blank"
                            rel="noopener">
                            <Map />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="E-mail" arrow="true">
                        <IconButton
                            className="icon-third"
                            href="mailto:support@nexivil.com">
                            <Mail />
                        </IconButton>
                    </Tooltip>
                </div>
                <Typography variant="body4" className="copyright">
                    Copyright © 2022 NEXIVIL Inc.
                </Typography>
            </Container>
        </RootBox>
    );
}

export default Footer;
