import React, { useState } from "react";
import { Box, Grid, styled, Typography } from "@mui/material";
import { motion } from "framer-motion/dist/framer-motion";
import Slider from "react-slick/lib/slider";
import CardItem from "./AboutCard/CardItem";
import ImageWrapper from "./Slick/ImageWrapper";
import SlideNumber from "./Slick/SlideNumber";

import aboutOne from "../../../assets/images/about01.png";
import aboutTwo from "../../../assets/images/about02.png";
import aboutThree from "../../../assets/images/about03.png";

const RootDiv = styled("div")(({ theme }) => ({
    maxWidth: "1198px",
    margin: "0 auto",
    paddingTop: "140px",

    [theme.breakpoints.down("lg")]: {
        paddingTop: "80px",
    },
    [theme.breakpoints.down("sm")]: {
        paddingTop: "40px",
    },
    [`& > .container-box`]: {
        position: "relative",
        width: "100%",
        height: "760px",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "128px",
        [theme.breakpoints.down("lg")]: {
            height: "590px",
            marginBottom: "68px",
        },
        [theme.breakpoints.down("sm")]: {
            height: "520px",
            marginBottom: "48px",
        },
        [`& > .left-card-container`]: {
            [`& > .card`]: {
                [`& > .left-box`]: {
                    position: "absolute",
                    right: "408px",
                    width: "60vw",
                    height: "760px",
                    backgroundColor: theme.palette.secondary.main,
                    opacity: 0.9,
                    [theme.breakpoints.down("lg")]: {
                        right: "456px",
                        maxWidth: "540px",
                        height: "540px",
                    },
                    [theme.breakpoints.down("md")]: {
                        right: "292px",
                        width: "446px",
                        height: "506px",
                    },
                    [theme.breakpoints.down("sm")]: {
                        width: "100vw",
                        height: "520px",
                        right: 0,
                    },
                    [`& > .content-box`]: {
                        position: "absolute",
                        right: 0,
                        top: 0,
                        width: "786px",
                        height: "760px",
                        textAlign: "center",
                        paddingTop: "75px",
                        [theme.breakpoints.down("lg")]: {
                            width: "540px",
                            height: "540px",
                            paddingTop: "54px",
                            paddingBottom: "37px",
                        },
                        [theme.breakpoints.down("md")]: {
                            width: "446px",
                            height: "506px",
                        },
                        [theme.breakpoints.down("sm")]: {
                            width: "100%",
                            height: "520px",
                            padding: "48px 26px",
                        },
                        [`& > .title`]: {
                            color: theme.palette.text.secondary,
                            marginBottom: "64px",
                            [theme.breakpoints.down("lg")]: {
                                fontSize: "46px",
                                lineHeight: "64px",
                                marginBottom: "47px",
                            },
                            [theme.breakpoints.down("sm")]: {
                                fontSize: "36px",
                                lineHeight: "42px",
                                marginBottom: "32px",
                            },
                        },
                        [`& > .typo-box`]: {
                            maxWidth: "308px",
                            height: "244px",
                            display: "flex",
                            alignItems: "center",
                            margin: "0 auto",
                            marginBottom: "64px",
                            padding: "50px",
                            backgroundColor: theme.palette.text.secondary,
                            [theme.breakpoints.down("lg")]: {
                                height: "154px",
                                padding: "37px 40px",
                                marginBottom: "36px",
                            },
                            [theme.breakpoints.down("sm")]: {
                                height: "160px",
                                padding: "36px 0",
                                marginBottom: "32px",
                            },
                            [`& > .subTitle`]: {
                                color: theme.palette.secondary.main,
                                [theme.breakpoints.down("lg")]: {
                                    fontSize: "28px",
                                    lineHeight: "40px",
                                },
                                [theme.breakpoints.down("sm")]: {
                                    fontSize: "36px",
                                    lineHeight: "48px",
                                },
                            },
                        },
                        [`& > .desc-wrapper`]: {
                            width: "320px",
                            margin: "0 auto",
                            lineHeight: 0,
                            [theme.breakpoints.down("lg")]: {
                                width: "356px",
                            },
                            [theme.breakpoints.down("sm")]: {
                                width: "229px",
                            },
                            [`& > .italic`]: {
                                [theme.breakpoints.down("lg")]: {
                                    fontSize: "18px",
                                    lineHeight: "24px",
                                    marginBottom: "24px",
                                },
                                [theme.breakpoints.down("sm")]: {
                                    fontWeight:
                                        theme.typography.fontWeightRegular,
                                    fontSize: "20px",
                                    lineHeight: "30px",
                                    marginBottom: "24px",
                                },
                            },
                            [`& > .desc`]: {
                                color: theme.palette.text.secondary,
                                [`&:nth-of-type(1)`]: {
                                    marginBottom: "24px",
                                },
                                [`&:nth-of-type(2)`]: {
                                    marginBottom: "12px",
                                },
                                [theme.breakpoints.down("lg")]: {
                                    fontSize: "16px",
                                    lineHeight: "26px",
                                },
                                [theme.breakpoints.down("sm")]: {
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                },
                            },
                            [`& > div`]: {
                                [theme.breakpoints.down("lg")]: {
                                    marginBottom: 0,
                                },
                                [theme.breakpoints.down("sm")]: {
                                    marginBottom: "12px",
                                },
                            },
                        },
                    },
                },
            },
        },
        [`& > .right-card-container`]: {
            [`& > .card`]: {
                [`& > .right-box`]: {
                    position: "absolute",
                    left: "408px",
                    top: "50px",
                    width: "60vw",
                    height: "760px",
                    backgroundColor: theme.palette.secondary.main,
                    opacity: 0.8,
                    [theme.breakpoints.down("lg")]: {
                        left: "456px",
                        maxWidth: "540px",
                        height: "540px",
                    },
                    [theme.breakpoints.down("md")]: {
                        left: "292px",
                        width: "446px",
                        height: "506px",
                    },
                    [theme.breakpoints.down("sm")]: {
                        display: "none",
                    },
                    [`& > .content-box`]: {
                        position: "absolute",
                        left: 0,
                        top: 0,
                        width: "786px",
                        height: "760px",
                        textAlign: "center",
                        [theme.breakpoints.down("lg")]: {
                            maxWidth: "540px",
                            width: "100%",
                            // width: "446px",
                            height: "540px",
                        },
                        [`& > .slick-slider`]: {
                            [`& > div`]: {
                                width: "786px",
                                height: "760px",
                                [theme.breakpoints.down("lg")]: {
                                    // width: "446px",
                                    width: "100%",
                                    // height: "506px",
                                    height: "540px",
                                },
                                [`& > img`]: {
                                    objectFit: "cover",
                                },
                            },
                        },
                    },
                },
            },
        },
    },
    [`& > .MuiGrid-container`]: {
        [theme.breakpoints.down("lg")]: {
            padding: "0 20px",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "0 16px 64px",
        },
    },
}));

export const images = [
    {
        id: 1,
        src: aboutOne,
        alt: "background image1",
    },
    {
        id: 2,
        src: aboutTwo,
        alt: "background image2",
    },
    {
        id: 3,
        src: aboutThree,
        alt: "background image3",
    },
];

const rightVariants = {
    offscreen: {
        x: 400,
    },
    onscreen: {
        x: 0,
        transition: {
            type: "spring",
            bounce: 0.2,
            duration: 2,
        },
    },
};

const leftVariants = {
    offscreen: {
        x: -400,
    },
    onscreen: {
        x: 0,
        transition: {
            type: "spring",
            bounce: 0.2,
            duration: 2,
        },
    },
};

function About() {
    const [slideIndex, setSlideIndex] = useState(0);

    const handleAfterChange = index => {
        setSlideIndex(index);
    };

    const slickSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        afterChange: handleAfterChange,
    };

    return (
        <RootDiv>
            <Box className="container-box">
                <motion.div
                    className="right-card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}>
                    <motion.div className="card" variants={rightVariants}>
                        <Box className="right-box">
                            <div className="content-box">
                                <Slider {...slickSettings}>
                                    {images.map(item => {
                                        return <ImageWrapper item={item} />;
                                    })}
                                </Slider>
                                <SlideNumber slideIndex={slideIndex} />
                            </div>
                        </Box>
                    </motion.div>
                </motion.div>
                <motion.div
                    className="left-card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}>
                    <motion.div className="card" variants={leftVariants}>
                        <Box className="left-box">
                            <div className="content-box">
                                <Typography variant="h2" className="title">
                                    NEXIVIL
                                </Typography>
                                <div className="typo-box">
                                    <Typography
                                        variant="subtitle2"
                                        className="subTitle">
                                        To the next Civilization
                                    </Typography>
                                </div>
                                <Box className="desc-wrapper">
                                    <Typography
                                        variant="body2"
                                        className="desc italic">
                                        Enhance your work style.
                                    </Typography>
                                    <Typography
                                        variant="body3"
                                        className="desc">
                                        Design automation consulting company
                                        NEXIVIL.
                                    </Typography>
                                    <div />
                                    <Typography
                                        variant="body3"
                                        className="desc">
                                        We boost your design work efficiency.
                                    </Typography>
                                </Box>
                            </div>
                        </Box>
                    </motion.div>
                </motion.div>
            </Box>
            <Grid container spacing={3}>
                <Grid item md={6} sm={6} xs={12}>
                    <CardItem
                        title="Join the team"
                        desc="Design the next generation of companies and embark on adventures with NEXIVIL."
                        buttonName="Apply"
                        href="https://www.rocketpunch.com/companies/nexivil/jobs"
                        target="_blank"
                        rel="noopener"
                    />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                    <CardItem
                        title="Investors & Partners"
                        desc="Begin producing valuable work full of creativity."
                        buttonName="Contact N"
                        href="mailto:support@nexivil.com"
                    />
                </Grid>
            </Grid>
        </RootDiv>
    );
}

export default About;
