import React from "react";

function ButtonDivGen({ text = "" }) {
  return (
    <div style={{ justifyContent: "center" }}>
      <React.Fragment>
        {text.split("").map((t) => (
          <span style={{ minWidth: t === " " ? 5 : undefined }}>{t}</span>
        ))}
      </React.Fragment>
    </div>
  );
}

export default ButtonDivGen;
