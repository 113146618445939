import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import theme from "./theme/theme";
import Header from "./components/Header/Header.js";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import ShowcasePage from "./pages/Showcase/ShowcasePage";
import ShowcaseDetailPage from "./pages/Showcase/ShowcaseDetailPage";
import ScrollTop from "./hooks/useScrollTop";
import { useInView } from "react-intersection-observer";
import NotFound from "./pages/NotFound/NotFound";

function App() {
    const [sectionOneRef, sectionOneInView] = useInView({ threshold: 0.3 });
    const [sectionTwoRef, sectionTwoInView] = useInView({ threshold: 0.3 });
    const [sectionThreeRef, sectionThreeInView] = useInView({ threshold: 0.3 });
    const [isShowNav, setIsShowNav] = useState(true);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <ScrollTop />
                {isShowNav && (
                    <nav>
                        <Header
                            sectionOneInView={sectionOneInView}
                            sectionTwoInView={sectionTwoInView}
                            sectionThreeInView={sectionThreeInView}
                        />
                    </nav>
                )}
                <Routes>
                    <Route
                        path="/"
                        element={
                            <Home
                                ref={{
                                    sectionOneRef: sectionOneRef,
                                    sectionTwoRef: sectionTwoRef,
                                    sectionThreeRef: sectionThreeRef,
                                }}
                            />
                        }
                    />
                    <Route
                        path="/showcase"
                        element={
                            <ShowcasePage
                                ref={{ sectionTwoRef: sectionTwoRef }}
                            />
                        }
                    />
                    <Route
                        path="/showcase/detail/:id"
                        element={
                            <ShowcaseDetailPage
                                ref={{ sectionTwoRef: sectionTwoRef }}
                            />
                        }
                    />
                    {/* 404 route */}
                    <Route
                        path="*"
                        element={<NotFound funcNav={setIsShowNav} />}
                    />
                </Routes>
                {isShowNav && (
                    <footer>
                        <Footer />
                    </footer>
                )}
            </Router>
        </ThemeProvider>
    );
}

export default App;
