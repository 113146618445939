import React from "react";
import { Button, Card, CardContent, styled, Typography } from "@mui/material";
import { motion } from "framer-motion/dist/framer-motion";

const CustomCard = styled(Card)(({ theme }) => ({
    maxWidth: "480px",
    height: "292px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: "24px",
    padding: "41px 51px",
    margin: "0 auto",
    marginBottom: "128px",
    textAlign: "center",
    [theme.breakpoints.down("lg")]: {
        width: "100%",
        height: "220px",
        marginBottom: "68px",
        padding: "12px 26px",
    },
    [theme.breakpoints.down("sm")]: {
        // width: "328px",
        height: "210px",
        marginBottom: 0,
        padding: "20px 40px",
    },
    [`& > .MuiCardContent-root`]: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "space-between",
        padding: 0,
        [`& > .title-container`]: {
            [`& > .contents`]: {
                [`& > .MuiTypography-subtitle2`]: {
                    // height: "30px",
                    color: theme.palette.text.secondary,
                    marginBottom: "28px",
                    [theme.breakpoints.down("lg")]: {
                        fontSize: "28px",
                        // lineHeight: "35px",
                        marginBottom: 0,
                    },
                    [theme.breakpoints.down("sm")]: {
                        fontSize: "26px",
                        // lineHeight: "40",
                        // marginBottom: "16px",
                    },
                },
            },
        },

        [`& > .contents-container`]: {
            lineHeight: 0,
            [`& > .contents`]: {
                [`& > .MuiTypography-body3`]: {
                    color: theme.palette.text.secondary,
                    fontSize: "16px",
                    fontWeight: theme.typography.fontWeightRegular,
                    lineHeight: "30px",
                    marginBottom: "32px",
                    [theme.breakpoints.down("lg")]: {
                        fontSize: "16px",
                        lineHeight: "22px",
                        marginBottom: "24px",
                    },
                    [theme.breakpoints.down("sm")]: {
                        fontSize: "16px",
                        lineHeight: "26px",
                        marginBottom: "22px",
                    },
                },
            },
        },
        [`& > .button-container`]: {
            [`& > .contents`]: {
                [`& > .MuiButton-root`]: {
                    maxWidth: "210px",
                    height: "42px",
                    margin: "0 auto",
                    padding: "8px 48px",
                    backgroundColor: theme.palette.text.secondary,
                    borderRadius: "30px",
                    color: theme.palette.secondary.main,
                    fontSize: "24px",
                    fontWeight: theme.typography.fontWeightBold,
                    lineHeight: "26px",
                    textTransform: "capitalize",
                    [`&:hover`]: {
                        color: theme.palette.text.secondary,
                        backgroundColor: theme.palette.secondary.main,
                        transition: "all 0.3s ease-in-out",
                    },
                    [theme.breakpoints.down("lg")]: {
                        height: "36px",
                        fontSize: "18px",
                        lineHeight: "20px",
                        padding: "9px 46px",
                    },
                    [theme.breakpoints.down("sm")]: {
                        height: "32px",
                        fontSize: "16px",
                        lineHeight: "17px",
                        padding: "8px 41px",
                    },
                },
            },
        },
    },
}));

const variants = {
    offscreen: {
        y: 100,
    },
    onscreen: duration => ({
        y: 0,
        transition: {
            type: "spring",
            bounce: 0.2,
            duration: duration,
        },
    }),
};

function CardItem(props) {
    const { title, desc, buttonName, href } = props;

    return (
        <motion.div
            className="card-container"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}>
            <motion.div className="card" variants={variants}>
                <CustomCard>
                    <CardContent>
                        <motion.div
                            className="title-container"
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}>
                            <motion.div
                                className="contents"
                                variants={variants}>
                                <Typography variant="subtitle2">
                                    {title}
                                </Typography>
                            </motion.div>
                        </motion.div>
                        <motion.div
                            className="contents-container"
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}>
                            <motion.div
                                className="contents"
                                variants={variants}>
                                <Typography variant="body3">{desc}</Typography>
                            </motion.div>
                        </motion.div>
                        <motion.div
                            className="button-container"
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}>
                            <motion.div
                                className="contents"
                                variants={variants}>
                                <Button
                                    variant="contained"
                                    component="button"
                                    disableFocusRipple
                                    disableRipple
                                    href={href}>
                                    {buttonName}
                                </Button>
                            </motion.div>
                        </motion.div>
                    </CardContent>
                </CustomCard>
            </motion.div>
        </motion.div>
    );
}

export default CardItem;
