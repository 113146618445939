import React from "react";
import { Grid, styled } from "@mui/material";
import Showcase from "../../components/Showcase/Showcase";

const RootDiv = styled("div")(({ theme }) => ({}));

function ShowcasePage(_, ref) {
    const { sectionTwoRef } = ref;

    return (
        <RootDiv ref={sectionTwoRef}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item md={12} xs={12}>
                    <Showcase />
                </Grid>
            </Grid>
        </RootDiv>
    );
}

export default React.forwardRef(ShowcasePage);
