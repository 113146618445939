import React, { useState } from "react";
import { Button, ListItem, Paper, Typography, styled } from "@mui/material";

const Root = styled(ListItem)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
}));

const Field = styled(Paper)(({ theme }) => ({
  flex: 1,
  flexDirection: "column",
  display: "flex",
  height: 100,
}));

const DndZone = styled(Button)(({ theme }) => ({
  flex: 1,
}));

export default function Main({v}) {
    // const [isEnter, setEnter] = useState(false);
    const [names, setNames] = useState([]);

    function handleOnBrowseFile(evt) {
        v.view_info?.function?.[0]?.(names => setNames(names));
        evt.stopPropagation();
        evt.preventDefault();
    }
    function handleOnDnDFile(evt) {
        console.log(Array.from(evt.dataTransfer.files).map(({ name }) => name));
        if (evt.dataTransfer.files.length)
            setNames(
                Array.from(evt.dataTransfer.files).map(({ name }) => name)
            );
        v.view_info?.function?.[1]?.(evt);
        evt.stopPropagation();
        evt.preventDefault();
    }

    return (
        <Root>
            <Field>
                <Typography variant="caption">{names.join(", ")}</Typography>
                <DndZone
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onDragOver={e => e.preventDefault()}
                    onDragEnter={() => {}}
                    onDragLeave={() => {}}
                    onDrop={handleOnDnDFile}
                    onClick={handleOnBrowseFile}>
                    Browse File <br />
                    or <br />
                    Drop Files
                </DndZone>
            </Field>
        </Root>
    );
}
