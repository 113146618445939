import React from "react";
import { Box, Card, CardMedia, Grid, styled } from "@mui/material";
import { ReactComponent as ContentCopyright } from "../../../../assets/images/svg/ContentCopyright.svg";
import { Link } from "react-router-dom";

const GridItem = styled(Grid)(({ theme }) => ({
    width: "100%",
    height: "100%",
    marginBottom: "56px",
    [theme.breakpoints.down("lg")]: {
        marginBottom: "12px",
    },
    [`& > .MuiCard-root`]: {
        position: "relative",
        borderRadius: "8px",
        marginBottom: "16px",
        [theme.breakpoints.down("lg")]: {
            height: "260px",
        },
        // [`& > .link-root`]: {
        [`& > .MuiCardMedia-root`]: {
            objectFit: "cover",
        },
        [`& > .content-copyright-btn`]: {
            position: "absolute",
            left: 12,
            bottom: 12,
        },
        // },
    },
    [`& > .keyword-wrapper`]: {
        display: "flex",
        alignItems: "center",
        marginBottom: "60px",
        [theme.breakpoints.down("lg")]: {
            marginBottom: 0,
        },
        [`& .keyword`]: {
            backgroundColor: "#fff",
            border: "0.5px solid #86898B",
            borderRadius: "22px",
            padding: "4px 20px",
            fontSize: "16px",
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.secondary.gray,
            lineHeight: "30px",
            marginRight: "4px",
            [theme.breakpoints.down("lg")]: {
                fontSize: "14px",
                lineHeight: "26px",
                padding: "2.5px 16.5px",
            },
        },
    },
}));

function ShowcaseListItem({ thumbnail, keywords }) {
    return (
        <GridItem item md={4} sm={6}>
            <Card>
                {/* <Link className="link-root" to={`/showcase/detail/${id}`}> */}
                <CardMedia
                    component="img"
                    height="300px"
                    image={thumbnail[0]}
                    alt="Showcase-thumbnail"
                />
                <ContentCopyright className="content-copyright-btn" />
                {/* </Link> */}
            </Card>
            {keywords ? (
                <Box className="keyword-wrapper">
                    <span className="keyword one">{keywords?.[0]}</span>
                    <span className="keyword two">{keywords?.[1]}</span>
                    <span className="keyword three">{keywords?.[2]}</span>
                </Box>
            ) : null}
        </GridItem>
    );
}

export default ShowcaseListItem;
