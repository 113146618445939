import React from "react";
import { Button, ListItem, styled } from "@mui/material";

const Root = styled(ListItem)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
}));

function Main({ v }) {
    function handleOnCLick() {
        v.view_info?.function?.();
        return;
    }

    return (
        <Root>
            <Button
                size="small"
                fullWidth
                color="primary"
                onClick={handleOnCLick}>
                {v.display_key}
            </Button>
        </Root>
    );
}

export default Main;
