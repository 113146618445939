import React, { useRef } from "react";
import { Box, styled } from "@mui/material";

import Trend from "../../components/Home/Trend/Trend";
import Showcase from "../../components/Home/Showcase/Showcase";
import About from "../../components/Home/About/About";
import Demo from "../../components/Home/Demo/Demo";
import ScrollTopButton from "../../components/ScrollTopButton/ScrollTopButton";
import Main from "../../components/Home/Main/Main";
import Editor from "../../components/Home/Editor/Editor";
import Apps from "../../components/Home/Apps/Apps";

const RootContainer = styled("div")(({ theme }) => ({
    [`& > .welcome-grid`]: {
        paddingTop: "100px",
        backgroundColor: "#1E1F24",
        [theme.breakpoints.down("lg")]: {
            paddingTop: 0,
        },
    },
    [`& > .editor-grid`]: {
        padding: "120px 0",
        backgroundColor: "#393838",
        [theme.breakpoints.down("sm")]: {
            padding: "40px 0",
        },
    },
    [`& > .apps-grid`]: {
        padding: "120px 0",
        backgroundColor: "#1e1f24",
        [theme.breakpoints.down("sm")]: {
            padding: "40px 0",
        },
    },
    [`& > .trend-grid`]: {
        paddingTop: "74px",
        paddingBottom: "80px",
        [theme.breakpoints.down("lg")]: {
            paddingTop: "45px",
            paddingBottom: "49px",
        },
    },
}));

function Home(_, ref) {
    const stickyDemoRef = useRef();
    const { sectionOneRef, sectionTwoRef, sectionThreeRef } = ref;

    return (
        <RootContainer>
            <Box
                className="welcome-grid screen welcome-section"
                ref={sectionOneRef}>
                <Main />
            </Box>
            <Box className="editor-grid screen editor-section">
                <Editor />
            </Box>
            <Box className="apps-grid screen apps-section">
                <Apps />
            </Box>
            <Box
                className="trend-grid screen trend-section"
                ref={sectionTwoRef}
                sx={{
                    backgroundColor: "#F5F5F6",
                }}>
                <Trend />
            </Box>
            <Box
                className="showcase-grid screen showcase-section"
                ref={sectionThreeRef}
                sx={{ backgroundColor: "#F22367" }}>
                <Showcase />
            </Box>
            <Demo
                ref={stickyDemoRef}
                className="demo-grid screen demo-section"
            />
            <Box
                className="about-grid screen about-section"
                sx={{ backgroundColor: "#1E1F24" }}>
                <About />
            </Box>

            <ScrollTopButton />
        </RootContainer>
    );
}

export default React.forwardRef(Home);
