import {
    MeshLine,
    MeshLineMaterial as _MeshLineMaterial,
    MeshLineRaycast,
} from "meshline";
import * as THREE from "three";

const resolution = { value: new THREE.Vector2(window.innerWidth, window.innerHeight) };
// let resolution = { value: new THREE.Vector2(200, 100) };

class MeshLineMaterial extends _MeshLineMaterial {
    constructor(opt) {
        if (!(!!opt.sizeAttenuation ?? true)) {
            if ((opt.lineWidth ?? 1) < 2) opt.lineWidth = 2;
        } else {
            if ((opt.lineWidth ?? 1) >= 1) opt.lineWidth *= 0.001;
        }
        super(opt);
        this.uniforms.resolution = resolution;
    }
}

export { MeshLine, MeshLineMaterial, MeshLineRaycast, resolution };
