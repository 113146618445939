import React from "react";
import { Card, CardContent, styled, Typography } from "@mui/material";
import { motion } from "framer-motion/dist/framer-motion";

const CustomCard = styled(Card)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    width: "276px",
    height: "226px",
    backgroundColor: "#651730",
    borderRadius: "10px",
    marginBottom: "64px",
    [theme.breakpoints.down("lg")]: {
        width: "100%",
        height: "226px",
        marginBottom: "40px",
    },
    [theme.breakpoints.down("sm")]: {
        width: "100%",
        height: "170px",
        marginBottom: 0,
    },
    [`& > .MuiCardContent-root`]: {
        padding: "60px 40px 0",
        margin: "0 auto",
        [theme.breakpoints.down("lg")]: {
            padding: "60px 25px",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "30px 10px",
        },
        [`& > .contents-container`]: {
            listStyle: "none",
            [`& > .icon`]: {
                [`& > div`]: {
                    width: "60px",
                    height: "60px",
                    margin: "0 auto",
                    marginBottom: "20px",
                    [theme.breakpoints.down("sm")]: {
                        width: "52px",
                        height: "52px",
                    },
                },
            },
            [`& > .typo`]: {
                textAlign: "center",
                lineHeight: 0,
                [`& > .MuiTypography-root`]: {
                    color: theme.palette.text.secondary,
                    [theme.breakpoints.down("lg")]: {
                        fontSize: "18px",
                        lineHeight: "30px",
                    },
                    [theme.breakpoints.down("sm")]: {
                        fontSize: "14px",
                        lineHeight: "20px",
                    },
                },
            },
        },
    },
}));

const variants = {
    offscreen: {
        y: 300,
    },
    onscreen: duration => ({
        y: 0,
        transition: {
            type: "spring",
            bounce: 0.2,
            duration: duration,
        },
    }),
};

function CardItem(props) {
    const { svg, desc } = props;

    return (
        <motion.div
            className="card-container"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}>
            <motion.div className="card" variants={variants}>
                <CustomCard>
                    <CardContent>
                        <motion.div
                            className="contents-container"
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}>
                            <motion.div className="icon" variants={variants}>
                                <div>{svg}</div>
                            </motion.div>
                        </motion.div>
                        <motion.div
                            className="contents-container"
                            initial="offscreen"
                            whileInView="onscreen"
                            viewport={{ once: true, amount: 0.8 }}>
                            <motion.div className="typo" variants={variants}>
                                <Typography variant="body3">{desc}</Typography>
                            </motion.div>
                        </motion.div>
                    </CardContent>
                </CustomCard>
            </motion.div>
        </motion.div>
    );
}

export default CardItem;
