import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { ButtonBase, Typography, styled } from "@mui/material";
import clsx from "clsx";
import { AutoSizer } from "react-virtualized";
import { ChevronDown, ChevronUp } from "@carbon/icons-react";

const StyledDiv = styled("div")(({ theme, type, defaultHeight }) => ({
    position: "relative",
    overflowY: "hidden",
    overflowX: "hidden",
    height: defaultHeight,
    display: "flex",
    // flex: hasOverflow ? "1 0 292px" : "1 0 28px",
    [`& button.expand-button`]: {
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: 30,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#fff",
        // backdropFilter: `blur(8px)`,
        borderRadius: "8px",
    },
    [`&.expanded`]: {
        height: "fit-content",
        [`& button.expand-button`]: {
            position: "sticky",
        },
    },
}));

const ShrinkButton = forwardRef(function ShrinkButton(
    { width, expanded, onExpanded, children, type },
    ref
) {
    const isMore = useMemo(
        () =>
            (expanded ? 110 : 81) <=
            (ref.current?.children?.[0]?.clientHeight ?? 0),
        [width]
    );

    function handleOnClickMore() {
        onExpanded();
    }

    useEffect(() => {
        if (!isMore && expanded) onExpanded(false);
    }, [isMore]);

    return (
        <React.Fragment>
            <Typography variant="body3" className="description">
                {children}
            </Typography>
            {(isMore || expanded) && (
                <ButtonBase
                    className="expand-button"
                    onClick={handleOnClickMore}
                    style={{
                        backgroundColor: type === "bim" ? "#7685d8" : "#44aeb5",
                    }}>
                    {expanded ? <ChevronUp /> : <ChevronDown />}
                </ButtonBase>
            )}
        </React.Fragment>
    );
});

function ShrinkComponent({ text, type, defaultHeight }) {
    const readmeRef = useRef();
    const [isExpanded, setExpanded] = useState(true);

    function handleOnClickExpand() {
        setExpanded(v => !v);
    }

    return (
        <StyledDiv
            ref={readmeRef}
            type={type}
            defaultHeight={defaultHeight}
            className={clsx(isExpanded && "expanded")}>
            <AutoSizer disableHeight style={{ width: "unset" }}>
                {({ width }) => (
                    <ShrinkButton
                        width={width}
                        expanded={isExpanded}
                        onExpanded={handleOnClickExpand}
                        ref={readmeRef}
                        type={type}>
                        {text ?? ""}
                    </ShrinkButton>
                )}
            </AutoSizer>
        </StyledDiv>
    );
}

export default ShrinkComponent;
