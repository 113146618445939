// import logo from './logo.svg';
// import './App.css';
import { Portal, Typography } from "@mui/material";
import React, { useLayoutEffect, useMemo, useRef } from "react";
import { useDemoStore } from "../Home/Demo/Demo";
import { Fabrica } from "./FabricaCanvas";
import Overlay from "./overlay";
import { THREECanvas } from "./ThreeCanvas";

function FabricaComponent(_, ref) {
    const aasaa = useRef();
    const _cachedContainerRef = useMemo(() => {
        const dom = document.createElement("div");
        dom.style = "width: 100%; height: 100%; overflow: hidden;";
        return dom;
    }, []);

    useLayoutEffect(() => {
        aasaa.current.appendChild(_cachedContainerRef);
        const unsub = useDemoStore.subscribe(({ fabricaRef }) => {
            if (!!fabricaRef)
                fabricaRef?.current?.appendChild(_cachedContainerRef);
            else aasaa.current.appendChild(_cachedContainerRef);
        });
        // _cachedContainerRef.current.className = "InnerWrapper";

        return () => {
            unsub();
            _cachedContainerRef.remove();
        };
    }, []);

    return (
        <div
            style={{
                width: "100vw",
                height: "calc(100vh - 100px)",
                position: "sticky",
                top: 100,
            }}>
            <THREECanvas />
            <div ref={aasaa} style={{ display: "none" }} />
            <Overlay ref={ref} />
            <Portal container={_cachedContainerRef}>
                <Fabrica />
            </Portal>
        </div>
    );
}

export default React.memo(React.forwardRef(FabricaComponent), () => true);
