import React, { useState } from "react";
import {
    Box,
    Card,
    CardContent,
    CardMedia,
    styled,
    Typography,
} from "@mui/material";
import { motion } from "framer-motion/dist/framer-motion";

import { ReactComponent as PlusIcon } from "../../../../assets/images/svg/Plus.svg";
import { ReactComponent as TrendClose } from "../../../../assets/images/svg/TrendClose.svg";

const CustomCard = styled(Card)(({ theme }) => ({
    position: "relative",
    maxWidth: "580px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    filter: "drop-shadow(0px 5px 25px rgba(0, 0, 0, 0.15))",
    [theme.breakpoints.down("lg")]: {
        width: "100%",
        height: "164px",
    },
    [`& > .MuiCardContent-root`]: {
        width: "50%",
        padding: 0,
        [`& > .card-title`]: {
            listStyle: "none",
            [`& > .MuiTypography-root`]: {
                whiteSpace: "pre-wrap",
                textAlign: "center",
                fontWeight: theme.typography.fontWeightSemiBold,
                fontSize: "32px",
                lineHeight: "38px",
                letterSpacing: "0em",
                flexGrow: 1,
                [theme.breakpoints.down("lg")]: {
                    fontSize: "18px",
                    fontWeight: 600,
                    lineHeight: "24px",
                },
            },
        },
    },
    [`& > .MuiCardMedia-img`]: {
        maxWidth: "50%",
        height: "300px",
        animationName: "clickCard",
        animationDuration: ".5s",
        animationIterationCount: 1,
    },
    [`& > .dog-eared`]: {
        position: "absolute",
        right: 0,
        bottom: 0,
        width: 0,
        height: 0,
        cursor: "pointer",
        borderBottom: `24px solid ${theme.palette.background.white}`,
        borderTop: "24px solid transparent",
        borderLeft: "24px solid transparent",
        borderRight: `24px solid ${theme.palette.background.white}`,
        [`& > svg`]: {
            position: "absolute",
            left: "4px",
            top: "4px",
        },
    },

    [`& > .MuiBox-root`]: {
        width: "290px",
        height: "300px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "16px",
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.secondary.main,
        [theme.breakpoints.down("lg")]: {
            width: "50%",
            padding: "32px 11px",
        },
        [`& > ul`]: {
            animationName: "clickCard",
            animationDuration: ".5s",
            animationIterationCount: 1,
            [theme.breakpoints.down("lg")]: {
                fontSize: "14px",
            },
            padding: "18px",
            [`& > li`]: {
                height: "100%",
                lineHeight: 0,
                [`& > .MuiTypography-root`]: {
                    [theme.breakpoints.down("lg")]: {
                        fontSize: "16px",
                        lineHeight: "24px",
                    },
                    [theme.breakpoints.down("sm")]: {
                        fontSize: "14px",
                        lineHeight: "20px",
                        letterSpacing: "0.02em",
                    },
                },
            },
        },
        [`& > .dog-eared`]: {
            position: "absolute",
            right: 0,
            bottom: 0,
            width: 0,
            height: 0,
            cursor: "pointer",
            borderBottom: `24px solid ${theme.palette.background.dark}`,
            borderTop: "24px solid transparent",
            borderLeft: "24px solid transparent",
            borderRight: `24px solid ${theme.palette.background.dark}`,
            [`& > svg`]: {
                position: "absolute",
                left: "4px",
                top: "4px",
            },
        },
    },
    "@keyframes clickCard": {
        "0%": {
            opacity: 0.5,
        },
        "50%": {
            opacity: 0.75,
        },
        "100%": {
            opacity: 1,
        },
        transition: ".5s ease",
    },
}));

const variants = {
    offscreen: {
        y: 300,
    },
    onscreen: {
        y: 0,
        transition: {
            type: "spring",
            bounce: 0.2,
            duration: 1,
        },
    },
};

function CardItem(props) {
    const { title, listOne, listTwo, listThree, trendImg } = props;
    const [clickedCard, setClickedCard] = useState(false);

    const onClickCard = () => {
        setClickedCard(prev => !prev);
    };

    return (
        <motion.div
            className="card-container"
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.8 }}>
            <motion.div className="card" variants={variants}>
                <CustomCard>
                    <CardContent>
                        <motion.li className="card-title" variants={variants}>
                            <Typography>{title}</Typography>
                        </motion.li>
                    </CardContent>
                    {!clickedCard ? (
                        <React.Fragment>
                            <CardMedia
                                component="img"
                                alt="trend thumbnail"
                                image={trendImg}
                            />
                            <div className="dog-eared" onClick={onClickCard}>
                                <PlusIcon />
                            </div>
                        </React.Fragment>
                    ) : (
                        <Box className="overlay">
                            <ul>
                                <li>
                                    <Typography variant="body3">
                                        {listOne}
                                    </Typography>
                                </li>
                                <li>
                                    <Typography variant="body3">
                                        {listTwo}
                                    </Typography>
                                </li>
                                {listThree && (
                                    <li>
                                        <Typography variant="body3">
                                            {listThree}
                                        </Typography>
                                    </li>
                                )}
                            </ul>
                            <div className="dog-eared" onClick={onClickCard}>
                                <TrendClose />
                            </div>
                        </Box>
                    )}
                </CustomCard>
            </motion.div>
        </motion.div>
    );
}

export default CardItem;
