import * as THREE from "three"
import * as THREELINE from "./meshline"

export const materials = {
    "CONC": new THREE.MeshMatcapMaterial({
        color: "rgb(160,160,160)",
        side: THREE.DoubleSide,
        transparent: true,
        opacity: 0.8,
    })
}

export const customMaterials = (color) => {
    return new THREE.MeshMatcapMaterial({
        color: color,
        side: THREE.DoubleSide,
        transparent: true,
        opacity: 8.0,
    })
}

export const lineMaterials = (width, color) => {
    const alpha = width <= 0.1 ? width * 1e3 / 5 : width / 5
    let mat = new THREELINE.MeshLineMaterial({
        color: color,
        linewidth: 1000,
        sizeAttenuation: false,
    })
    mat.uniforms.resolution = { value: new THREE.Vector2(window.innerWidth / alpha, window.innerHeight / alpha) };
    return mat
}