import React, {
    useEffect,
    useImperativeHandle,
    useLayoutEffect,
    useState,
} from "react";
import {
    Box,
    Button,
    buttonClasses,
    Paper,
    styled,
    SvgIcon,
    svgIconClasses,
    Typography,
    typographyClasses,
    useMediaQuery,
} from "@mui/material";
import { ReactComponent as RedoIcon } from "./assets/redo.svg";
import { ReactComponent as EditIcon } from "./assets/edit.svg";
import { controller } from "./ThreeCanvas";
import { MathUtils } from "three";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";

const StyledDiv = styled("div")(({ theme }) => ({
    position: "absolute",
    inset: 0,
    zIndex: 1,
    background:
        "linear-gradient(0deg, #000000 2.91%, rgba(17, 17, 17, 0) calc(91.48% - 470px))",
    [`& > div.innerdiv`]: {
        maxWidth: 1198,
        position: "absolute",
        inset: 0,
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        margin: "0 auto",
        paddingTop: 58,
        paddingBottom: 58,
        [`& > .${typographyClasses.root}.title`]: {
            [theme.breakpoints.down("lg")]: {
                textAlign: "center",
            },
        },
        [`& > div.guidance`]: {
            [`& > div.guideInidicator`]: {
                backgroundColor: "#fff",
                borderRadius: 8,
                padding: "6px 16px",
                width: "fit-content",
                [`& > .${typographyClasses.root}`]: {
                    fontSize: "16px",
                    lineHeight: "17.41px",
                    fontWeight: 700,
                },
            },
            [`& > .${typographyClasses.root}.description`]: {
                fontSize: "18px",
                lineHeight: "19.58px",
                marginTop: "18px",
                fontWeight: 100,
                minHeight: 55,
            },
            [`& > div.actions`]: {
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                [`& > button`]: {
                    width: 100,
                    height: 32,
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: 8,
                    fontSize: "14px",
                    lineHeight: "15.23px",
                    fontWeight: 600,
                    color: "#000",
                    [`&:first-child`]: {
                        marginRight: 12,
                    },
                    [`& .${svgIconClasses.root}`]: {
                        width: 16,
                        height: 14,
                    },
                },
            },
        },
    },
}));

function Overlay(_, ref) {
    const isMob = useMediaQuery(theme => theme.breakpoints.down("lg"), {
        noSsr: true,
    });
    const [title, setTitle] = useState("");
    const [Comp, setComp] = useState(<React.Fragment />);
    useImperativeHandle(ref, () => ({
        setTitle: v => setTitle(v),
        setComp: v => setComp(v),
    }));
    useLayoutEffect(() => {
        function onDeviceOrientationChangeEvent(e) {
            controller.rotateTo(
                e.gamma * MathUtils.DEG2RAD,
                e.beta * MathUtils.DEG2RAD,
                false
            );
        }
        window.addEventListener(
            "deviceorientation",
            onDeviceOrientationChangeEvent,
            false
        );
        return () => {
            window.removeEventListener(
                "deviceorientation",
                onDeviceOrientationChangeEvent,
                false
            );
        };
    }, []);

    return (
        <React.Fragment>
            <StyledDiv
                onMouseMove={evt => {
                    const pos = evt.currentTarget.getBoundingClientRect();

                    controller.rotateTo(
                        30 *
                            ((evt.clientX - pos.left) / pos.width - 0.5) *
                            MathUtils.DEG2RAD,
                        (30 * ((evt.clientY - pos.top) / pos.height - 0.5) +
                            60) *
                            MathUtils.DEG2RAD,
                        false
                    );
                    // console.log(evt.clientX - pos.left, evt.clientY - pos.top);
                }}>
                <AnimatePresence exitBeforeEnter>{Comp}</AnimatePresence>
                <div className="innerdiv">
                    <AnimatePresence exitBeforeEnter>
                        <Typography
                            className="title"
                            component={isMob ? motion.h4 : motion.h1}
                            variant={isMob ? "h4" : "h1"}
                            color="primary"
                            key={title}
                            initial={{ y: 10, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ duration: 0.2 }}
                            // style={{ scale: scrollYProgress }}
                        >
                            {title}
                        </Typography>
                    </AnimatePresence>
                    <div className="guidance">
                        {/* <Paper className="guideInidicator">
            <Typography variant="body1">Guide</Typography>
          </Paper> */}
                        <Typography className="description" variant="body1">
                            {/* Edit the node to interact with it */}
                        </Typography>
                        {/* <div className="actions">
            <Button startIcon={<SvgIcon component={EditIcon} inheritViewBox />}>
              edit
            </Button>
            <Button startIcon={<SvgIcon component={RedoIcon} inheritViewBox />}>
              redo
            </Button>
          </div> */}
                    </div>
                </div>
            </StyledDiv>
        </React.Fragment>
    );
}

export default React.forwardRef(Overlay);
