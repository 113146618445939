import {
  ListItem,
  ListItemAvatar,
  styled,
  TextField,
  textFieldClasses,
  Typography,
} from "@mui/material";
import React from "react";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  [`& .${textFieldClasses.root}`]: {
    flex: 1,
    maxWidth: 250,
    [`& .inputfield`]: {
      textAlign: "right",
    },
  },
}));

function Main({ v }) {
  let currentData = JSON.stringify(v.value[v.view_info.key]) || "";

  function handleOnBlur(e) {
    currentData = e.target.value;
    v.value[v.view_info.key] = JSON.parse(currentData);
  }

  return (
    <StyledListItem>
      <ListItemAvatar>
        <Typography variant="overline">{v.display_key}</Typography>
      </ListItemAvatar>
      <TextField
        type="string"
        size="small"
        onBlur={handleOnBlur}
        inputProps={{ className: "inputfield" }}
        // name={formName}
        defaultValue={currentData}
        // disabled={disabled}
      />
    </StyledListItem>
  );
}

export default Main;
