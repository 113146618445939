import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import "litegraph.js/css/litegraph.css";
import { LiteGraph, LGraphCanvas, LGraph, LGraphNode } from "litegraph.js";
import { baseWidget, interfaceWidget } from "litegraph.js/src/nodes";
// import "./index.css";
import { BoxGeometry, Mesh, Group, MeshBasicMaterial } from "three";
import { memoryAddress } from "./ThreeCanvas";
import { genBeamMesh } from "./module";
import graphTerrian from "./assets/graph-terrian.json";

// import {texx} from "./mapbox"
import * as THREE from "three";
import { useDemoStore } from "../Home/Demo/Demo";
// import { terrian } from "./mapbox";
// import {memiry}
const titleRegex = new RegExp(/^const .*$/);
class drawNode extends LGraphNode {
    title = "scene";
    description = "Drawing model to the THREE.js scene.";
    constructor() {
        super();
        this.addInput("mesh", "");
    }
    onExecute() {
        let _mesh = this.getInputData(0);
        if (memoryAddress.scene.children.length > 1) {
            // memoryAddress.scene.children[1].children.forEach((_mesh) =>
            //   _mesh.geometry.dispose()
            // );
            memoryAddress.scene.children.pop();
        }
        if (_mesh instanceof Mesh || _mesh instanceof Group) {
            // _mesh.rotateY(Math.PI / 2);
            // _mesh.rotateZ(Math.PI / 2);
            memoryAddress.scene.add(_mesh);
        }
    }
}

class Cube extends LGraphNode {
    title = "cube";
    description = "Return Cube Mesh";
    constructor() {
        super();
        this.addInput("test", "");
        this.addOutput("mesh", "");
    }
    onExecute() {
        const geometry = new BoxGeometry(1, 1, 1);
        const material = new MeshBasicMaterial({ color: 0x00ff00 });
        const cube = new Mesh(geometry, material);
        this.setOutputData(0, cube);
    }
}

class group extends LGraphNode {
    title = "group";
    description = "make mesh group";
    constructor() {
        super();
        this.addInput("mesh", "");
        this.addInput("mesh", "");
        this.addInput("mesh", "");
        this.addOutput("mesh group", "");
    }
    onExecute() {
        const mesh0 = this.getInputData(0);
        const mesh1 = this.getInputData(1);
        const mesh2 = this.getInputData(2);
        const meshGroup = new Group();
        if (!!mesh0) meshGroup.add(mesh0);
        if (!!mesh1) meshGroup.add(mesh1);
        if (!!mesh2) meshGroup.add(mesh2);
        this.setOutputData(0, meshGroup);
    }
}

class RCBeam extends LGraphNode {
    title = "RCBeam";
    description = "Return RCBeam Mesh";
    constructor() {
        super();
        this.addInput("b", "");
        this.addInput("h", "");
        this.addOutput("meshGroup", "");
    }
    onExecute() {
        const b = this.getInputData(0);
        const h = this.getInputData(1);
        this.meshGroup = genBeamMesh(b, h);
        this.meshGroup.translateZ(-10);
        this.setOutputData(0, this.meshGroup);
    }
}

export const Fabrica = React.memo(
    function Fabrica() {
        const [isLoaded, setLoaded] = useState(false);
        const lgRef = useRef();
        const lgCanvas = useRef();
        const lggraph = useRef();

        const resizer = () => {
            // lggraph.current.nodes.forEach(({ pos }) => {
            //   pos[1] = window.innerHeight - 500;
            // });

            // lggraph.current._nodes.forEach(({ pos, size }, idx) => {
            //   // pos[0] =
            //   //   window.innerWidth - 300 + (200 * (idx - 1) - (idx === 0 ? 50 : 0));
            //   // pos[1] = window.innerHeight / 2;
            //   // pos[0] = 0;
            //   // pos[1] = 0;
            // });
            lgCanvas.current.setZoom(
                window.innerWidth > 1100
                    ? 0.9
                    : window.innerWidth > 900
                    ? 0.6
                    : window.innerWidth > 600
                    ? 0.7
                    : 0.7,
                [lgRef.current.width * 0.5, lgRef.current.height * 0.5]
            );
            lgCanvas.current.resize();
        };

        useEffect(() => {
            if (isLoaded) {
                function loadGraph(grpah = graphTerrian) {
                    lggraph.current = new LGraph(grpah);
                    // console.log(lggraph.current._nodes.filter((v) => !!v["view_info"]));
                    useDemoStore.setState({
                        inputs: lggraph.current._nodes.filter(
                            v =>
                                !!v["view_info"] &&
                                !titleRegex.test(v.title.toLowerCase() ?? "")
                        ),
                    });
                    lggraph.current.onAfterChange = function () {
                        console.log(lggraph.current._nodes);
                        try {
                            lggraph.current.runStep();
                            useDemoStore.setState({
                                inputs: lggraph.current._nodes.filter(
                                    v =>
                                        !!v["view_info"] &&
                                        !titleRegex.test(
                                            v.title.toLowerCase() ?? ""
                                        )
                                ),
                            });
                        } catch {}
                        console.log(
                            JSON.stringify(lggraph.current.serialize())
                        );
                    };
                    LGraphCanvas.DEFAULT_BACKGROUND_IMAGE = null;
                    lgCanvas.current = new LGraphCanvas(
                        lgRef.current,
                        lggraph.current,
                        {}
                    );
                    lgCanvas.current.zoom_modify_alpha = false;

                    resizer();
                    lggraph.current.runStep();
                }
                useDemoStore.setState({
                    faricaResizer: () => {
                        lgCanvas.current.resize();
                    },
                    run: () => {
                        lggraph.current.runStep();
                    },
                    loadGraph: v => loadGraph(v),
                });

                loadGraph(graphTerrian);

                window.addEventListener("resize", resizer);
            }
        }, [isLoaded]);

        useLayoutEffect(() => {
            baseWidget(LiteGraph);
            interfaceWidget(LiteGraph);
            // TODO: for debug
            // LiteGraph.debug = true;
            LiteGraph.allow_scripts = true;
            LiteGraph.ASYNC = true;

            // LiteGraph.NODE_BOX_OUTLINE_COLOR="#EA1C5F"

            // lgCanvas.current.background_image = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkiAQAAF8AW+qFhiYAAAAASUVORK5CYII=";

            LiteGraph.registerNodeType("3d/scene", drawNode);
            LiteGraph.registerNodeType("3d/cube", Cube);
            LiteGraph.registerNodeType("3d/RCBeam", RCBeam);
            LiteGraph.registerNodeType("3d/group", group);
            window.test = "test";
            window.THREE = THREE;
            import("./mapbox").then(({ terrian }) => {
                LiteGraph.registerNodeType("3d/terrian", terrian);
                setLoaded(true);
            });

            return () => {
                window.removeEventListener("resize", resizer);
            };
        }, []);

        // const resizer = debounce(function ({ height, width }) {
        //     lgCanvas.current.resize(width, height - 8);
        // }, 300);

        return <canvas ref={lgRef} id="mycanvas" tabIndex="1" />;
    },
    () => true
);
