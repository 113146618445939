import React from "react";
import { styled } from "@mui/material";

const WrapperDiv = styled("div")(({ theme }) => ({
  position: "relative",
}));

function ImageWrapper({ item }) {
  return (
    <WrapperDiv className="image-wrapper" key={item.id}>
      <img src={item.src} alt={item.alt} />
    </WrapperDiv>
  );
}

export default ImageWrapper;
