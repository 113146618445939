import React from "react";
import { Box, Card, CardMedia, Grid, styled } from "@mui/material";
import { ReactComponent as ContentCopyright } from "../../../../../assets/images/svg/ContentCopyright.svg";
import { Link } from "react-router-dom";

const GridItem = styled(Grid)(({ theme }) => ({
    width: "100%",
    height: "100%",
    marginBottom: "56px",
    [theme.breakpoints.down("lg")]: {
        marginBottom: "75px",
    },
    [theme.breakpoints.down("sm")]: {
        marginBottom: "1px",
        [`&:nth-last-of-type(1)`]: {
            marginBottom: "66px",
        },
    },
    [`& > .MuiCard-root`]: {
        position: "relative",
        height: "100%",
        borderRadius: "8px",
        marginBottom: "16px",
        [`& > .link-root`]: {
            [`& > .MuiCardMedia-root`]: {
                height: "300px",
                objectFit: "cover",
                [theme.breakpoints.down("lg")]: {
                    height: "280px",
                },
                [theme.breakpoints.down("sm")]: {
                    height: "260px",
                },
            },
            [`& > .content-copyright-btn`]: {
                position: "absolute",
                left: 12,
                bottom: 12,
            },
        },
    },
    [`& > .keyword-wrapper`]: {
        display: "flex",
        alignItems: "center",
        [`& .keyword`]: {
            backgroundColor: "#fff",
            border: "0.5px solid #86898B",
            borderRadius: "22px",
            padding: "4px 20px",
            fontSize: "16px",
            fontWeight: theme.typography.fontWeightRegular,
            color: theme.palette.secondary.gray,
            lineHeight: "30px",
            marginRight: "4px",
            [theme.breakpoints.down("lg")]: {
                padding: "4px 18px",
                fontSize: "14px",
                lineHeight: "28px",
            },
            [theme.breakpoints.down("sm")]: {
                padding: "2.5px 16.5px",
                fontSize: "14px",
                lineHeight: "26px",
            },
        },
    },
}));

function MoreProjectItem({ id, image, keyword }) {
    return (
        <GridItem item md={4} sm={6}>
            <Card>
                <Link className="link-root" to={`/showcase/detail/${id}`}>
                    <CardMedia
                        component="img"
                        image={image}
                        alt="showcase thumbnail"
                    />
                    <ContentCopyright className="content-copyright-btn" />
                </Link>
            </Card>
            <Box className="keyword-wrapper">
                <span className="keyword one">{keyword[0]}</span>
                <span className="keyword two">{keyword[1]}</span>
                <span className="keyword three">{keyword[2]}</span>
            </Box>
        </GridItem>
    );
}

export default MoreProjectItem;
