import {
  autocompleteClasses,
  ListItem,
  ListItemAvatar,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import React from "react";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
  [`& .${autocompleteClasses.root}`]: {
    flex: 1,
    maxWidth: 250,
    [`& .${autocompleteClasses.inputRoot}`]: {
      textAlign: "right",
    },
  },
}));

function Main({ v }) {
  let currentData = v.value[v.view_info.key];

  const options_list_in =
    (v.view_info?.values ?? false) && Array.isArray(v.view_info.values)
      ? v.view_info.values
      : v.value?.values?.split(";").map((i) => i.trim()) ?? [];

  function handleOnBlur(e) {
    currentData = e.target.value;
    v.value[v.view_info.key] = currentData;
  }

  return (
    <StyledListItem>
      <ListItemAvatar>
        <Typography variant="overline">{v.display_key}</Typography>
      </ListItemAvatar>
      <Autocomplete
        size="small"
        options={options_list_in}
        getOptionLabel={(v) => v}
        defaultValue={currentData}
        onBlur={handleOnBlur}
        renderInput={(params) => <TextField {...params} label="" />}
      />
    </StyledListItem>
  );
}

export default Main;
