import React, { forwardRef } from "react";
import {
    Box,
    Button,
    buttonClasses,
    Grid,
    styled,
    Typography,
} from "@mui/material";
import { motion } from "framer-motion/dist/framer-motion";
import CardItem from "./ShowcaseCard/CardItem";

import { ReactComponent as BrochureIcon } from "../../../assets/images/svg/Brochure_Icon.svg";
import { ReactComponent as SVG1 } from "../../../assets/images/svg/Icon1.svg";
import { ReactComponent as SVG2 } from "../../../assets/images/svg/Icon2.svg";
import { ReactComponent as SVG3 } from "../../../assets/images/svg/Icon3.svg";
import { ReactComponent as SVG4 } from "../../../assets/images/svg/Icon4.svg";
import { ReactComponent as ContentCopyright } from "../../../assets/images/svg/ContentCopyright.svg";
import ButtonDivGen from "../../ButtonAnimation/ButtonDivGen";

const RootDiv = styled("div")(({ theme }) => ({
    maxWidth: "1198px",
    margin: "0 auto",
    paddingTop: "32px",
    paddingBottom: "80px",
    [theme.breakpoints.down("lg")]: {
        padding: "32px 20px 80px",
    },
    [theme.breakpoints.down("sm")]: {
        padding: "40px 16px 49px",
    },
    [`& > .showcaseTitle`]: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "26px",
        padding: "0 16p",
        [theme.breakpoints.down("lg")]: {
            marginBottom: "32px",
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom: "22px",
            flexDirection: "column",
        },

        [`& > .card-container`]: {
            display: "flex",
            flexDirection: "column",
            flex: 1,
            [`& > .card`]: {
                maxWidth: "1198px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                [`& > .MuiBox-root`]: {
                    [`& > .MuiTypography-h3`]: {
                        fontWeight: theme.typography.fontWeightThin,
                        color: theme.palette.text.secondary,
                        [theme.breakpoints.down("lg")]: {
                            fontSize: "40px",
                            lineHeight: "68px",
                        },
                        [theme.breakpoints.down("sm")]: {
                            fontSize: "36px",
                            lineHeight: "42px",
                        },
                    },
                    [`& > .MuiTypography-h2`]: {
                        color: theme.palette.text.secondary,
                        [theme.breakpoints.down("lg")]: {
                            fontSize: "46px",
                            lineHeight: "64px",
                        },
                        [theme.breakpoints.down("sm")]: {
                            fontSize: "36px",
                            lineHeight: "42px",
                            // marginBottom: "24px",
                        },
                    },
                },
                [`& > .MuiButton-root`]: {
                    display: "flex",
                    alignSelf: "flex-end",
                    width: "200px",
                    height: "52px",
                    color: theme.palette.text.primary,
                    fontWeight: theme.typography.fontWeightBold,
                    fontSize: "21px",
                    lineHeight: "23px",
                    letterSpacing: "0em",
                    backgroundColor: theme.palette.primary.light,
                    borderRadius: "10px",
                    textTransform: "capitalize",
                    transform: "translateY(y) translateZ(0)",
                    transition: "transform .44s ease, boxShadow .44s ease",
                    transitionDuration: ".5s",
                    trnasitionProperty: "transform",
                    // boxShadow: "0 6px 6px #0e0f14",
                    [theme.breakpoints.down("lg")]: {
                        width: "200px",
                        fontSize: "21px",
                    },
                    [theme.breakpoints.down("sm")]: {
                        maxWidth: "132px",
                        height: "36px",
                        fontSize: "14px",
                        lineHeight: "15px",
                        padding: "6px 20px",
                        marginBottom: 0,
                    },
                    [`& > .MuiButton-startIcon`]: {
                        [`& > svg`]: {
                            [`& path`]: {
                                stroke: "black",
                                [`&:last-child`]: {
                                    fill: "black",
                                },
                            },
                        },
                    },
                    [`&:hover`]: {
                        color: theme.palette.primary.main,
                        transition: "all 0.3s ease-in-out",
                        boxShadow: "0 8px 8px #b40d43",
                        transform: "translateY(-8px)",
                        animationName: "btnUpDown",
                        animationDuration: ".2s",
                        animationDelay: 1,
                        animationTimingFunction: "linear",
                        animationIterationCount: 1,
                        animationDirection: "alternate",
                        [`& > .MuiButton-startIcon`]: {
                            [`& > svg`]: {
                                [`& path`]: {
                                    stroke: theme.palette.primary.main,
                                    transition: "all 0.3s ease-in-out",
                                    [`&:last-child`]: {
                                        fill: theme.palette.primary.main,
                                        transition: "all 0.3s ease-in-out",
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },
    [`& > .card-container-second`]: {
        [`& > .card`]: {
            [`& > .video-container`]: {
                position: "relative",
                width: "100vw",
                height: "100%",
                left: "50%",
                transform: "translate(-50%)",
                marginBottom: "64px",
                overflowX: "hidden",
                [theme.breakpoints.down("sm")]: {
                    padding: 0,
                },
                [`& > video`]: {
                    width: "100vw",
                    height: "520px",
                    objectFit: "cover",
                    overflowX: "hidden",
                    [theme.breakpoints.down("sm")]: {
                        height: "320px",
                    },
                },
                [`& > .copyright-icon`]: {
                    // position: "absolute",
                    // left: "16px",
                    // bottom: "30px",
                    position: "relative",
                    width: "1198px",
                    margin: "0 auto",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    [`& > div`]: {
                        position: "absolute",
                        left: 16,
                        bottom: 30,
                        display: "flex",
                        alignItems: "center",
                        [`& > svg`]: {
                            marginRight: "10px",
                        },
                        [`& > .version`]: {
                            color: theme.palette.primary.light,
                        },
                    },
                },
            },
            [`& > .version`]: {
                fontSize: "16px",
                lineHeight: "17px",
                color: theme.palette.primary.light,
            },
        },
    },
    [`& > .card-container-third`]: {
        [`& > .card`]: {
            [`& > .${buttonClasses.root}`]: {
                maxWidth: "324px",
                height: "64px",
                display: "flex",
                margin: "0 auto",
                padding: "16px 68px",
                borderRadius: "60px",
                backgroundColor: "#000",
                fontWeight: theme.typography.fontWeightRegular,
                fontSize: "32px",
                lineHeight: "31px",
                letterSpacing: "0em",
                color: theme.palette.text.secondary,
                // textTransform: "none",
                transform: "translateY(-4ps) translateZ(0)",
                transition: "transform .44s ease, boxShadow .44s ease",
                transitionDuration: ".5s",
                transitionProperty: "transform",
                [`& > div`]: {
                    display: "flex",
                    overflow: "hidden",
                    textShadow: "0 28px 0 #fff",
                    [`& > span`]: {
                        display: "block",
                        backfaceVisibility: "hidden",
                        fontStyle: "normal",
                        transition: `transform .44s ease`,
                        [`&:nth-of-type(1)`]: { transitionDelay: ".05s" },
                        [`&:nth-of-type(2)`]: { transitionDelay: ".1s" },
                        [`&:nth-of-type(3)`]: { transitionDelay: ".15s" },
                        [`&:nth-of-type(4)`]: { transitionDelay: ".2s" },
                        [`&:nth-of-type(5)`]: { transitionDelay: ".25s" },
                        [`&:nth-of-type(6)`]: { transitionDelay: ".3s" },
                        [`&:nth-of-type(7)`]: { transitionDelay: ".35s" },
                        [`&:nth-of-type(8)`]: { transitionDelay: ".4s" },
                        [`&:nth-of-type(9)`]: { transitionDelay: ".45s" },
                        [`&:nth-of-type(10)`]: { transitionDelay: ".5s" },
                        [`&:nth-of-type(11)`]: { transitionDelay: ".55s" },
                        [`&:nth-of-type(12)`]: { transitionDelay: ".6s" },
                        [`&:nth-of-type(13)`]: { transitionDelay: ".65s" },
                        [`&:nth-of-type(14)`]: { transitionDelay: ".7s" },
                        [`&:nth-of-type(15)`]: { transitionDelay: ".75s" },
                    },
                },
                [`&:hover`]: {
                    shadow: "0 4px 20px -2px #84143852",
                    boxShadow: "0 8px 8px #841438",
                    transform: "translateY(-8px)",
                    [`& > div`]: {
                        [`& > span`]: {
                            transform: `translateY(-28px) translateZ(0)`,
                        },
                    },
                },
                [theme.breakpoints.down("lg")]: {
                    fontSize: "28px",
                    lineHeight: "30px",
                    padding: "14px 41px",
                },
                [theme.breakpoints.down("sm")]: {
                    width: "168px",
                    height: "44px",
                    fontSize: "20px",
                    padding: "11px 25px",
                    marginTop: "36px",
                },
            },
        },
    },
}));

const variants = {
    offscreen: {
        y: 300,
    },
    onscreen: {
        y: 0,
        transition: {
            type: "spring",
            bounce: 0.2,
            duration: 1,
        },
    },
};

function Showcase() {
    return (
        <RootDiv>
            <div className="showcaseTitle">
                <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}>
                    <motion.div className="card" variants={variants}>
                        <Box>
                            <Typography variant="h3">What is</Typography>
                            <Typography variant="h2">
                                Design Express?
                            </Typography>
                        </Box>
                        <Button
                            variant="contained"
                            startIcon={<BrochureIcon />}
                            disableFocusRipple
                            disableRipple
                            href="https://www.nexivil.com/media/public/dx_promote.pdf"
                            target="_blank"
                            rel="noopener"
                            sx={{ mb: "10px" }}>
                            Brochure
                        </Button>
                    </motion.div>
                </motion.div>
            </div>
            <motion.div
                className="card-container-second"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}>
                <motion.div className="card" variants={variants}>
                    <Box className="video-container">
                        <video
                            playsInline
                            autoPlay
                            muted
                            loop
                            poster="https://x.nexivil.com/media/public/0262-1968.webm_snapshot_a4giHW4.png">
                            <source
                                src="https://x.nexivil.com/media/public/0262-1968_FXIxzd5.webm"
                                type="video/webm"
                            />
                            <source
                                src="https://x.nexivil.com/media/public/0001-1968.mp4"
                                type="video/mp4"
                            />
                        </video>
                        <Box className="copyright-icon">
                            <div>
                                <ContentCopyright />
                                <Typography variant="h5" className="version">
                                    v 1.0
                                </Typography>
                            </div>
                        </Box>
                    </Box>
                </motion.div>
            </motion.div>
            <Grid container spacing={3} sx={{ marginBottom: 0 }}>
                <Grid item md={3} xs={6}>
                    <CardItem
                        desc="Industrial solutions built by users"
                        svg={<SVG1 />}
                    />
                </Grid>
                <Grid item md={3} xs={6}>
                    <CardItem desc="Easily create on the web" svg={<SVG2 />} />
                </Grid>
                <Grid item md={3} xs={6}>
                    <CardItem desc="Share with users" svg={<SVG3 />} />
                </Grid>
                <Grid item md={3} xs={6}>
                    <CardItem
                        desc="Extensions optimized for digital twins"
                        svg={<SVG4 />}
                    />
                </Grid>
            </Grid>
            <motion.div
                className="card-container-third"
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true, amount: 0.8 }}>
                <motion.div className="card" variants={variants}>
                    <Button
                        variant="contained"
                        component="button"
                        disableFocusRipple
                        disableRipple
                        href="/showcase"
                        disabled
                        rel="noopener">
                        <ButtonDivGen text="Showcase" />
                    </Button>
                </motion.div>
            </motion.div>
        </RootDiv>
    );
}

export default Showcase;
