import React from "react";
// import { get } from "lodash";
import {
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography,
} from "@mui/material";

function Item({ v }) {
    return (
        <ListItem>
            <ListItemAvatar>
                <Typography>{v.display_key}</Typography>
            </ListItemAvatar>
            <ListItemText>{JSON.stringify(v.value[v.view_info.key] || {})}</ListItemText>
        </ListItem>
    );
}

export default Item;

// render 에서 컴포넌트 재 렌더 지정하는게 어떨까
// leva에서 render : get=>처럼
