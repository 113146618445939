import React from "react";
import {
    Grid,
    IconButton,
    iconButtonClasses,
    styled,
    Typography,
    typographyClasses,
    useMediaQuery,
} from "@mui/material";
import clsx from "clsx";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import BIM_Icon from "../../../assets/images/svg/bim_icon.svg";
import IoT_Icon from "../../../assets/images/svg/iot_icon.svg";
import ShrinkComponent from "../../Shrink";

const RootDiv = styled("div")(({ theme }) => ({
    maxWidth: "1198px",
    margin: "0 auto",
    [theme.breakpoints.down("lg")]: { margin: "0 20px" },
    [`& > .${typographyClasses.root}`]: {
        display: "flex",
        justifyContent: "center",
        color: theme.palette.text.secondary,
        marginBottom: "48px",
        [theme.breakpoints.down("sm")]: {
            fontWeight: 700,
            fontSize: "24px",
            lineHeight: "26px",
            letterSpacing: "2%",
            marginBottom: "24px",
        },
    },
    [`& div.card-container`]: {
        maxWidth: "580px",
        width: "100%",
        minHeight: "648px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "48px 24px",
        boxSizing: "border-box",
        borderRadius: "20px",
        color: theme.palette.text.secondary,
        textAlign: "center",
        [theme.breakpoints.down("md")]: {
            minHeight: "720px",
            justifyContent: "space-between",
        },
        [theme.breakpoints.down("sm")]: {
            minHeight: "584px",
        },
        [`& > div.icon`]: {
            width: "98px",
            height: "98px",
            margin: "0 auto",
            marginBottom: "32px",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
        },
        [`& span.description`]: {
            display: "flex",
            flex: 1,
            marginTop: "32px",
        },
        [`& > span.authorship`]: {
            minHeight: "60px",
            marginBottom: "32px",
        },
        [`& > .${iconButtonClasses.root}`]: {
            width: "48px",
            height: "48px",
            margin: "0 auto",
            color: theme.palette.text.secondary,
            backgroundColor: "#222",
        },
    },
    [`& .bim`]: {
        backgroundColor: "#7685d8",
        [`& > div.icon`]: {
            backgroundImage: `url(${BIM_Icon})`,
        },
        [`& > span.authorship`]: {
            color: "#BBC5FF",
        },
    },
    [`& .iot`]: {
        backgroundColor: "#44aeb5",
        [`& > div.icon`]: {
            backgroundImage: `url(${IoT_Icon})`,
        },
        [`& > span.authorship`]: {
            color: "#77f7ff",
        },
    },
}));

export function AppCardComponent({ title, description, authorship, type }) {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

    return (
        <div className={clsx("card-container", type)}>
            <div className="icon" />
            <Typography variant="subtitle3" className="title">
                {title}
            </Typography>
            {isMobile ? (
                <ShrinkComponent
                    text={description}
                    defaultHeight={180}
                    type={type}
                />
            ) : (
                <Typography variant="body3" className="description">
                    {description}
                </Typography>
            )}
            <Typography variant="body3" className="authorship">
                {authorship}
            </Typography>
            <IconButton
                component="a"
                href="https://x.nexivil.com"
                target="_blank"
                rel="noopener"
                disableTouchRipple
                disableRipple
                disableFocusRipple
                focusRipple>
                <ArrowForwardIcon />
            </IconButton>
        </div>
    );
}

function Apps() {
    return (
        <RootDiv>
            <Typography variant="subtitle1">
                Apps For Civil Engineers
            </Typography>
            <Grid container spacing={3}>
                <Grid item md={6} sm={6} xs={12}>
                    <AppCardComponent
                        type="bim"
                        title="BIM"
                        description="Building Information Modeling (BIM) is a digital representation of physical and functional characteristics of a facility. A BIM is a shared knowledge resource for information about a facility forming a reliable basis for decisions during its life-cycle; defined as existing from earliest conception to demolition."
                        authorship="- The US BIM Standard Project Committee -"
                    />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                    <AppCardComponent
                        type="iot"
                        title="IoT"
                        description="The Internet of things (IoT) describes physical objects(or groups of such objects) with sensors, processing ability, software and other technologies that connect and exchange data with other devices and systems over the Internet or other communications networks."
                        authorship="- Wikipedia -"
                    />
                </Grid>
            </Grid>
        </RootDiv>
    );
}

export default Apps;
