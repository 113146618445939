import React from "react";
import {
    Avatar,
    avatarClasses,
    Button,
    ButtonBase,
    styled,
    Typography,
    typographyClasses,
} from "@mui/material";
import clsx from "clsx";

import EditorHeader from "../../../assets/images/svg/EditorHeader.svg";
import EditorHeaderTablet from "../../../assets/images/svg/EditorHeaderTablet.svg";
import EditorHeaderMobile from "../../../assets/images/svg/EditorHeaderMobile.svg";
import AddWidget from "../../../assets/images/svg/AddWidget.svg";
import NewDesign from "../../../assets/images/svg/NewDesign.svg";
import NodeSearch from "../../../assets/images/svg/NodeSearch.svg";
import Thumbnail from "../../../assets/images/project1.png";

const RootDiv = styled("div")(({ theme }) => ({
    [`& > .${typographyClasses.root}`]: {
        display: "flex",
        justifyContent: "center",
        color: theme.palette.text.secondary,
        marginBottom: "48px",
        [theme.breakpoints.down("lg")]: {
            fontSize: "30px",
            lineHeight: "32px",
            letterSpacing: "2%",
            marginBottom: "36px",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "24px",
            lineHeight: "26px",
            letterSpacing: "2%",
            marginBottom: "24px",
        },
    },
    [`& > div`]: {
        position: "relative",
        maxWidth: "1194px",
        width: "100%",
        height: "652px",
        margin: "0 auto",
        [theme.breakpoints.down("lg")]: {
            padding: "0 48px",
        },
        [theme.breakpoints.down("sm")]: {
            height: "604px",
            padding: "0 20px",
        },
        [`& > div.editor-header`]: {
            maxWidth: "1194px",
            width: "100%",
            height: "48px",
            margin: "0 auto",
            backgroundImage: `url(${EditorHeader})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            [theme.breakpoints.down("lg")]: {
                maxWidth: "672px",
                backgroundImage: `url(${EditorHeaderTablet})`,
            },
            [theme.breakpoints.down("sm")]: {
                maxWidth: "320px",
                backgroundImage: `url(${EditorHeaderMobile})`,
            },
        },
        [`& > div.container`]: {
            position: "relative",
            display: "flex",
            height: "100%",
            margin: "0 auto",
            [theme.breakpoints.down("lg")]: { maxWidth: "672px" },
            [theme.breakpoints.down("sm")]: { maxWidth: "320px" },
            [`& > div.add-widget`]: {
                position: "absolute",
                top: "70px",
                left: "205px",
                width: "265px",
                height: "42px",
                margin: "0 auto",
                backgroundImage: `url(${AddWidget})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                [theme.breakpoints.down("lg")]: {
                    top: "32px",
                    left: "175px",
                },
                [theme.breakpoints.down("sm")]: {
                    top: "32px",
                    left: "65px",
                    width: "186px",
                    backgroundSize: "contain",
                },
            },
            [`& > div.new-design`]: {
                position: "absolute",
                top: "183px",
                left: "70px",
                width: "460px",
                height: "165px",
                margin: "0 auto",
                backgroundImage: `url(${NewDesign})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                [theme.breakpoints.down("lg")]: {
                    top: "111px",
                    left: "30px",
                },
                [theme.breakpoints.down("sm")]: {
                    width: "293px",
                    maxHeight: "98px",
                    top: "86px",
                    left: "8px",
                    backgroundSize: "contain",
                },
            },
            [`& > div.node-search`]: {
                position: "absolute",
                top: "409px",
                left: "275px",
                width: "217px",
                height: "31px",
                margin: "0 auto",
                backgroundImage: `url(${NodeSearch})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                [theme.breakpoints.down("lg")]: {
                    top: "95px",
                    left: "423px",
                },
                [theme.breakpoints.down("sm")]: {
                    width: "173px",
                    top: "188px",
                    left: "100px",
                    backgroundSize: "contain",
                },
            },
            [`& > div.design-card-container`]: {
                position: "absolute",
                top: "108px",
                right: "18px",
                width: "595px",
                height: "424px",
                [theme.breakpoints.down("lg")]: {
                    width: "672px",
                    height: "395px",
                    // margin: "0 auto",
                    top: "234px",
                    right: "unset",
                },
                [theme.breakpoints.down("sm")]: {
                    maxWidth: "320px",
                    width: "100%",
                    height: "302px",
                    top: "237px",
                    // margin: "0 auto",
                    display: "flex",
                    justifyContent: "center",
                },
                [`& > div.card-container`]: {
                    position: "absolute",
                    maxWidth: "288px",
                    width: "100%",
                    padding: "16px",
                    border: "1px solid #4f4d4d",
                    borderRadius: "4px",
                    [`& > div.card-thumbnail`]: {
                        width: "100%",
                        height: "160px",
                        backgroundImage: `url(${Thumbnail})`,
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        marginBottom: "24px",
                    },
                    [`& > div.card-info`]: {
                        display: "flex",
                        flexDirection: "row",
                        alginItems: "center",
                        marginBottom: "20px",
                        [`& > div.icon`]: {
                            width: "24px",
                            height: "24px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: theme.palette.text.secondary,
                            fontSize: "14px",
                            backgroundColor: "#ea1c5f",
                            borderRadius: "2px",
                            marginRight: "12px",
                        },
                        [`& > div.typo-wrapper`]: {
                            [`& > p.title`]: {
                                fontSize: "14px",
                                fontWeight: 500,
                                lineHeight: "20px",
                                letterSpacing: 0.6,
                                color: "#fff",
                            },
                            [`& > p.description`]: {
                                fontSize: "12px",
                                fontWeight: 400,
                                lineHeight: "20px",
                                letterSpacing: 0.32,
                                color: "#8d8d8d",
                            },
                        },
                    },
                    [`& > div.button-wrapper`]: {
                        display: "flex",
                        justifyContent: "space-between",
                        [`& > button.avatar-button`]: {
                            [`& > .${avatarClasses.root}`]: {
                                width: "24px",
                                height: "24px",
                                marginRight: "12px",
                            },
                            [`& > .${typographyClasses.root}`]: {
                                fontSize: "12px",
                                fontWeight: 400,
                                lineHeight: "20px",
                                letterSpacing: 0.32,
                                color: "#8d8d8d",
                            },
                        },
                        [`& > button.button`]: {
                            width: "56px",
                            height: "20px",
                            padding: "2px 0",
                            textTransform: "none",
                            fontSize: "12px",
                            fontWeight: 500,
                            lineHeight: "16px",
                            color: theme.palette.text.secondary,
                            borderColor: theme.palette.text.secondary,
                        },
                    },
                },
                [`& > .first-card`]: {
                    left: 0,
                    bottom: 0,
                    [theme.breakpoints.down("lg")]: { left: "30px" },
                    [theme.breakpoints.down("sm")]: { left: "unset" },
                },
                [`& > .second-card`]: {
                    top: 0,
                    right: 0,
                    [theme.breakpoints.down("lg")]: { right: "50px" },
                    [theme.breakpoints.down("sm")]: { display: "none" },
                },
            },
            [`& > div.file-container`]: {
                position: "absolute",
                bottom: 0,
                left: "50%",
                transform: "translate(-50%, 0)",
                width: "958px",
                height: "42px",
                padding: "8px 16px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                backgroundColor: "#4f4d4d",
                [theme.breakpoints.down("lg")]: { display: "none" },
                [`& > div.file-info`]: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    flex: 3,
                    [`& > div.icon`]: {
                        width: "24px",
                        height: "24px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: theme.palette.text.secondary,
                        fontSize: "14px",
                        backgroundColor: "#ea1c5f",
                        borderRadius: "2px",
                    },
                    [`& > p.typo`]: {
                        fontSize: "14px",
                        fontWeight: 500,
                        lineHeight: "20px",
                        letterSpacing: 0.6,
                        color: theme.palette.text.secondary,
                        paddingLeft: "12px",
                    },
                    [`& > p.description`]: {
                        color: "#8d8d8d",
                        paddingLeft: "16px",
                    },
                },
                [`& > button.avatar-button`]: {
                    flex: 1,
                    [`& > .${avatarClasses.root}`]: {
                        width: "24px",
                        height: "24px",
                        marginRight: "12px",
                    },
                    [`& > .${typographyClasses.root}`]: {
                        fontSize: "12px",
                        fontWeight: 400,
                        lineHeight: "20px",
                        letterSpacing: 0.32,
                        color: "#8d8d8d",
                    },
                },
                [`& > button.button`]: {
                    width: "56px",
                    height: "20px",
                    padding: "2px 0",
                    textTransform: "none",
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: "16px",
                    color: theme.palette.text.secondary,
                    borderColor: theme.palette.text.secondary,
                },
            },
        },
    },
}));

export function DesignCardComponent({ order, title, description, namespace }) {
    return (
        <div className={clsx("card-container", order)}>
            <div className="card-thumbnail" />
            <div className="card-info">
                <div className="icon">D</div>
                <div className="typo-wrapper">
                    <Typography className="title">{title}</Typography>
                    <Typography className="description">
                        {description}
                    </Typography>
                </div>
            </div>
            <div className="button-wrapper">
                <ButtonBase size="small" className="avatar-button">
                    <Avatar />
                    <Typography>{namespace}</Typography>
                </ButtonBase>
                <Button variant="outlined" size="small" className="button">
                    Open
                </Button>
            </div>
        </div>
    );
}

export function DesignFileComponent({ title, description, namespace }) {
    return (
        <div className="file-container">
            <div className="file-info">
                <div className="icon">D</div>
                <Typography className="typo title">{title}</Typography>
                <Typography className="typo description">
                    {description}
                </Typography>
            </div>
            <ButtonBase className="avatar-button" size="small">
                <Avatar />
                <Typography>{namespace}</Typography>
            </ButtonBase>
            <Button variant="outlined" size="small" className="button">
                Open
            </Button>
        </div>
    );
}

function Editor() {
    return (
        <RootDiv>
            <Typography variant="subtitle1">How DESIGN Editor Works</Typography>
            <div>
                <div className="editor-header" />
                <div className="container">
                    <div className="add-widget" />
                    <div className="new-design" />
                    <div className="node-search" />
                    <div className="design-card-container">
                        <DesignCardComponent
                            order="first-card"
                            title="IFC - Editor"
                            description="The IFC Engine library"
                            namespace="hd"
                        />
                        <DesignCardComponent
                            order="second-card"
                            title="sectionDesign_Circular"
                            description="This is not a pipe"
                            namespace="NEXIVIL"
                        />
                    </div>
                    <DesignFileComponent
                        title="IFC - Editor"
                        description="The IFC Engine library"
                        namespace="hd"
                    />
                </div>
            </div>
        </RootDiv>
    );
}

export default Editor;
