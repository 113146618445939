import { ListItem, ListItemAvatar, TextField, Typography, styled } from "@mui/material";
import React from "react";

const Root = styled(ListItem)(({theme}) => ({
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
}))

const Field = styled(TextField)(({ theme }) => ({
    flex: 1,
    maxWidth: 250,
  }));

function Main({ v }) {
    let currentData = v.value[v.view_info.key] || 0;

    function handleOnBlur(e) {
        currentData = e.target.value;
        v.value[v.view_info.key] = currentData;
    }

    return (
        <Root >
            <ListItemAvatar>
                <Typography variant="overline">{v.display_key}</Typography>
            </ListItemAvatar>
            <Field
                type="string"
                size="small"
                onBlur={handleOnBlur}
                inputProps={{
                    style: {textAlign: "right"}
                  }}
                // name={formName}
                defaultValue={currentData}
                // disabled={disabled}
            />
        </Root>
    );
}

export default Main;
