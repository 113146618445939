import React, { useEffect, useState } from "react";
import {
    AppBar,
    Button,
    Container,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    styled,
    Toolbar,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as LogoWhite } from "../../assets/images/svg/Logo_White.svg";
import { ReactComponent as MenuIcon } from "../../assets/images/svg/MenuIcon.svg";
import { ReactComponent as LogoOrigin } from "../../assets/images/svg/Logo_Origin.svg";

import { Link } from "react-router-dom";
import MobileMenu from "./Mobile/MobileMenu";

const Navbar = styled(AppBar)(({ theme }) => ({
    width: "100vw",
    display: "flex",
    top: 0,
    left: 0,
    boxShadow: "none",
    opacity: 0.9,
    [theme.breakpoints.down("lg")]: {},
    [`&	.MuiContainer-root`]: {
        // width: "unset",
        // margin: "unset",
        padding: 0,
        [`& .MuiToolbar-root`]: {
            width: "100%",
            height: "100px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 16px",
            [`& > .logo-link`]: {
                width: "152px",
                [theme.breakpoints.down("lg")]: {
                    width: "66px",
                },
            },
            [`&	.nav-links`]: {
                display: "flex",
                justifyContent: "center",
                listStyle: "none",
                margin: "0 auto",
                [theme.breakpoints.down("lg")]: {
                    display: "none",
                },
                [`& > div`]: {
                    display: "flex",
                    flexDirection: "row",
                    [`& > .MuiListItem-root`]: {
                        width: "unset",
                        [`& > .MuiListItemButton-root, .showcase-link`]: {
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "18px",
                            fontWeight: theme.typography.fontWeightRegular,
                            lineHeight: "20px",
                            letterSpacing: "0.07em",
                            padding: 0,
                            textDecoration: "none",
                            backgroundColor: "transparent",
                            [`&:hover`]: {
                                transition: "all 0.5s ease-in-out",
                                backgroundColor: "transparent",
                            },
                        },
                    },
                    [`& > .mobile-start-button`]: {
                        display: "none",
                    },
                },
            },
            [`&	.start-button`]: {
                position: "relative",
                appearance: "none",
                boxShadow: "0 0 0 1px rgba(255, 255, 255, .8) inset",
                padding: "12px 24px",
                fontSize: theme.typography.fontSize,
                fontWeight: theme.typography.fontWeightBold,
                lineHeight: "17px",
                letterSpacing: "0.02em",
                borderRadius: 4,
                textTransform: "capitalize",
                backgroundColor: "transparent",
                [`&:before, &:after`]: {
                    content: "''",
                    position: "absolute",
                    height: 0,
                    width: "1px",
                    transition:
                        "height .4s .4s cubic-bezier(0.86, 0, 0.07, 1), width .4s cubic-bezier(0.86, 0, 0.07, 1)",
                },
                [`&:hover`]: {
                    backgroundColor: "transparent",
                    [`&:before, &:after`]: {
                        width: "100%",
                        height: "100%",
                        borderRadius: 4,
                        transition:
                            "height .4s cubic-bezier(0.86, 0, 0.07, 1), width .4s .4s cubic-bezier(0.86, 0, 0.07, 1)",
                    },
                },
                [theme.breakpoints.down("lg")]: {
                    display: "none",
                },
                [theme.breakpoints.down("sm")]: {
                    display: "none",
                },
            },
            [`&	.mobile-menu-icon`]: {
                display: "none",
                [theme.breakpoints.down("lg")]: {
                    display: "block",
                    color: theme.palette.text.primary,
                    border: "none",
                    outline: "none",
                },
            },
        },
    },
}));

function Header({ sectionOneInView, sectionTwoInView, sectionThreeInView }) {
    const [isMobile, setIsMobile] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const defaultColor = {
        color: !sectionOneInView && sectionTwoInView ? "#20212c" : "#fff",
    };
    const hoverColor = {
        "&:hover": {
            color: sectionTwoInView
                ? "#F22367"
                : sectionThreeInView
                ? "#000"
                : "#F22367",
        },
    };
    const borderColor = {
        borderColor: !sectionOneInView && sectionTwoInView ? "#20212c" : "#fff",
    };

    function handleToggleMenu() {
        setIsMobile(prev => !prev);
    }

    function scrollToAbout() {
        function scrollFunc() {
            const about =
                document.getElementsByClassName("about-section")[0].offsetTop -
                100;
            window.scrollTo({ top: about, behavior: "smooth" });
        }

        if (location.pathname === "/showcase") {
            navigate("/");
            setTimeout(() => {
                scrollFunc();
            }, 1000);
        } else {
            scrollFunc();
        }

        if (isMobile) setIsMobile(prev => !prev);
    }

    return (
        <React.Fragment>
            <Navbar
                className="active-mobile-nav"
                style={{
                    backgroundColor: sectionOneInView
                        ? "#1E1F24"
                        : sectionTwoInView
                        ? "#fff"
                        : sectionThreeInView
                        ? "#F22367"
                        : "#1E1F24",
                }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Link to="/" className="logo-link">
                            {sectionOneInView || !sectionTwoInView ? (
                                <LogoWhite />
                            ) : (
                                <LogoOrigin />
                            )}
                        </Link>
                        <List className="nav-links">
                            <div>
                                <ListItem>
                                    <ListItemButton
                                        component="a"
                                        href="https://x.nexivil.com/"
                                        target="_blank"
                                        rel="noopener"
                                        disableTouchRipple
                                        disableRipple
                                        disableFocusRipple
                                        focusRipple
                                        onClick={() => setIsMobile(false)}
                                        sx={[defaultColor, hoverColor]}>
                                        DX Platform
                                    </ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton
                                        component={Link}
                                        to="/showcase"
                                        className="showcase-link"
                                        disableTouchRipple
                                        disableRipple
                                        disableFocusRipple
                                        focusRipple
                                        onClick={() => setIsMobile(false)}
                                        sx={[defaultColor, hoverColor]}>
                                        Showcase
                                    </ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton
                                        component="a"
                                        onClick={scrollToAbout}
                                        disableTouchRipple
                                        disableRipple
                                        disableFocusRipple
                                        focusRipple
                                        sx={[defaultColor, hoverColor]}>
                                        Company
                                    </ListItemButton>
                                </ListItem>
                            </div>
                        </List>
                        <Button
                            variant="outlined"
                            className="start-button"
                            disableFocusRipple
                            disableRipple
                            href="https://passport.nexivil.com"
                            target="_blank"
                            rel="noopener"
                            sx={[
                                defaultColor,
                                hoverColor,
                                borderColor,
                                {
                                    "&:before": {
                                        boxShadow: sectionTwoInView
                                            ? "1px 1px 0 rgba(234, 28, 95, 1) inset"
                                            : sectionThreeInView
                                            ? "1px 1px 0 rgba(0, 0, 0, 1) inset"
                                            : "1px 1px 0 rgba(234, 28, 95, 1) inset",
                                        bottom: 0,
                                        left: 0,
                                    },
                                    "&:after": {
                                        boxShadow: sectionTwoInView
                                            ? "-1px -1px 0 rgba(234, 28, 95, 1) inset"
                                            : sectionThreeInView
                                            ? "-1px -1px 0 rgba(0, 0, 0, 1) inset"
                                            : "-1px -1px 0 rgba(234, 28, 95, 1) inset",
                                        top: 0,
                                        right: 0,
                                    },
                                },
                            ]}>
                            Sign In / Up
                        </Button>
                        <IconButton
                            className="mobile-menu-icon"
                            size="large"
                            edge="start"
                            onClick={handleToggleMenu}>
                            <MenuIcon
                                fill={
                                    !sectionOneInView && sectionTwoInView
                                        ? "#000"
                                        : "#F5F5F6"
                                }
                            />
                        </IconButton>
                    </Toolbar>
                </Container>
            </Navbar>

            {isMobile && (
                <MobileMenu
                    handleToggleMenu={handleToggleMenu}
                    scrollToAbout={scrollToAbout}
                />
            )}
        </React.Fragment>
    );
}

export default Header;
