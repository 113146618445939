import React, { useState, useRef } from "react";
import { isNumber, isObject, isBoolean, isString, isArray } from "lodash";

import {
  UnknownController,
  NumberController,
  ObjectController,
  ToggleController,
  ButtonController,
  StringController,
  ArrayStringController,
  OptionsController,
  FileUploaderController,
} from ".";
import {
  ButtonBase,
  Dialog,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
// import { UIfunc } from "../../../func";
// import DoneIcon from "@mui/icons-material/Done";
// import { Edit } from "@mui/icons-material";

// TODO : Bake Object (오브젝트 안에 있는 요를를 밖으로 빼는 경우)
// function _populateObj({ keyID, v, graphID, editor }) {}
export function CompSwitch({ v, editor }) {
  if (Object.keys(v).length === 0 || !v.view_info) {
    return <React.Fragment />;
  } else if (v.view_info.type === "button") return <ButtonController v={v} />;
  else if (v.view_info.type === "options") return <OptionsController v={v} />;
  else if (v.view_info.type === "file") return <FileUploaderController v={v} />;
  else if (
    v.view_info.type === "boolean" ||
    isBoolean(v.value[v.view_info.key])
  )
    return <ToggleController v={v} />;
  else if (v.view_info.type === "number" || isNumber(v.value[v.view_info.key]))
    return <NumberController v={v} />;
  else if (v.view_info.type === "string" || isString(v.value[v.view_info.key]))
    return <StringController v={v} />;
  else if (
    v.view_info.type === "array.string" ||
    isArray(v.value[v.view_info.key])
  )
    return <ArrayStringController v={v} />;
  else if (
    v.view_info.type === "object" ||
    isObject(v.value[v.view_info.key])
  ) {
    return <ObjectController v={v} editor={false} />;
  } else return <UnknownController v={v} />;
}

function Items({ v, editor }) {
  const [state, setState] = useState(false);
  const inputRef = useRef();

  function handleOnEditViewInfo() {
    setState((b) => {
      if (b === true) {
        for (let k in v.view_info) {
          v.view_info[k] = null;
        }

        const parsedData = JSON.parse(inputRef.current.value);
        if (!!parsedData.title ?? false) v.display_key = parsedData.title;
        v.view_info = Object.assign(v.view_info, parsedData);
      }
      return !b;
    });
  }

  return editor ? (
    <React.Fragment>
      <ButtonBase onClick={handleOnEditViewInfo}>
        <Typography variant="caption" color="textSecondary">
          Edit
        </Typography>
      </ButtonBase>
      <CompSwitch v={v} editor={editor} />
      <Dialog open={state} onClose={handleOnEditViewInfo}>
        <DialogContent>
          <TextField
            inputRef={inputRef}
            multiline
            defaultValue={JSON.stringify(v.view_info ?? {})}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  ) : (
    <CompSwitch v={v} editor={editor} />
  );
}

// export function MainContainer({
//     dataTree,
//     rootChildren = { current: null },
//     editor = false,
// }) {
//     const childrenInputRef = useRef();
//     const [, forceUpdate] = useReducer(x => x + 1, 0);
//     const [items, setItems] = useState(OrderedMap());
//     // const [delayedItems, setDelayedItems] = useState([]);

//     const _handleOnUpdate = useCallback(
//         function _handleOnUpdate() {
//             if (dataTree.size < 1) return;
//             const isrootChildsettingsExist =
//                 List.isList(rootChildren.current) &&
//                 rootChildren.current.size > 0;

//             const folderItems = dataTree
//                 .filter((item, k) => {
//                     return (
//                         (item?.view_info ?? false) &&
//                         Array.isArray(item.view_info.children) &&
//                         item.view_info.children.length > 0 &&
//                         (!isrootChildsettingsExist ||
//                             rootChildren.current.includes(k))
//                     );
//                 })
//                 .reduce((arrayData, { view_info }, k) => {
//                     return union(arrayData, view_info.children);
//                 }, []);

//             const rootItems = dataTree.filter(
//                 (_, k) => folderItems.indexOf(k) === -1
//             );

//             if (isrootChildsettingsExist) {
//                 // Validate RootChildren
//                 const _rootChildren = rootChildren.current.filter(k =>
//                     rootItems.has(k)
//                 );

//                 if (editor) {
//                     rootChildren.current = _rootChildren;
//                     childrenInputRef.current.value = JSON.stringify(
//                         rootChildren.current
//                     );
//                 }

//                 setItems(
//                     OrderedMap(_rootChildren.map(k => [k, rootItems.get(k)]))
//                 );
//             } else {
//                 if (editor) {
//                     const childItems = rootItems.map((_, k) => k).toList();
//                     rootChildren.current = childItems;

//                     childrenInputRef.current.value = JSON.stringify(
//                         rootChildren.current
//                     );
//                 }
//                 setItems(rootItems);
//             }
//         },
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//         [dataTree]
//     );

//     function handleOnUpdate() {
//         _handleOnUpdate();
//         forceUpdate();
//     }

//     function handleOnChangeRootChildren() {
//         try {
//             rootChildren.current = List(
//                 JSON.parse(childrenInputRef.current.value)
//             );
//             handleOnUpdate();
//         } catch {}
//     }

//     useEffect(() => {
//         _handleOnUpdate();
//     }, [_handleOnUpdate]);

//     useEffect(() => {
//         UIfunc.updateFromStepper = debounce(handleOnUpdate, 300);
//         return () => {
//             UIfunc.updateFromStepper = function () {};
//         };
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, []);
//     // useEffect(() => {
//     //     setItems(delayedItems);
//     // }, [delayedItems]);

//     return (
//         <React.Fragment>
//             {editor && (
//                 <ListItem>
//                     <TextField
//                         label="children"
//                         inputRef={childrenInputRef}
//                         style={{ flex: 1 }}
//                     />
//                     <Divider flexItem orientation="vertical" />
//                     <IconButton
//                         color="primary"
//                         onClick={handleOnChangeRootChildren}>
//                         <DoneIcon />
//                     </IconButton>
//                 </ListItem>
//             )}
//             {items.entrySeq().map(([k, v]) => (
//                 <Items v={v} editor={editor} />
//             ))}
//             <ListItem style={{ color: "#86898b", justifyContent: "center" }}>
//                 <ButtonBase
//                     variant="Text"
//                     color="inherit"
//                     onClick={handleOnUpdate}>
//                     END
//                 </ButtonBase>
//             </ListItem>
//         </React.Fragment>
//     );
// }

// export default MainContainer;

export function SubContainer({ dataTree, editor=false }) {
  return (
    <React.Fragment>
      {dataTree.map(([k, v]) => (
        <Items v={v} editor={editor} />
      ))}
    </React.Fragment>
  );
}
