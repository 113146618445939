import React from "react";
import { Grid, styled, Typography } from "@mui/material";
import { motion } from "framer-motion/dist/framer-motion";
import CardItem from "./TrendCard/CardItem";

import trendImgOne from "../../../assets/images/trend01.png";
import trendImgTwo from "../../../assets/images/trend02.png";

const RootDiv = styled("div")(({ theme }) => ({
    maxWidth: "1198px",
    margin: "0 auto",
    [`& > div`]: {
        [theme.breakpoints.down("lg")]: {
            padding: "0 20px",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "0 16px",
        },
        [`& > .card-container`]: {
            [`& > .card`]: {
                [`& > .MuiTypography-root`]: {
                    marginBottom: "32px",
                    [theme.breakpoints.down("lg")]: {
                        marginBottom: "22px",
                        fontSize: "30px",
                        lineHeight: "33px",
                    },
                    [theme.breakpoints.down("sm")]: {
                        fontSize: "24px",
                        lineHeight: "26px",
                    },
                },
            },
        },
    },
}));

const cardVariants = {
    offscreen: {
        y: 300,
    },
    onscreen: {
        y: 0,
        transition: {
            type: "spring",
            bounce: 0.2,
            duration: 1,
        },
    },
};

function Trend() {
    return (
        <RootDiv>
            <div>
                <motion.div
                    className="card-container"
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}>
                    <motion.div className="card" variants={cardVariants}>
                        <Typography variant="subtitle1">Trend</Typography>
                    </motion.div>
                </motion.div>
                <Grid container spacing={3}>
                    <Grid item md={6} sm={6} xs={12}>
                        <CardItem
                            title={"Manufacturing\n&\nConstruction"}
                            listOne="Real-time Monitoring & Analysis"
                            listTwo="Digital Mock-Up For User"
                            trendImg={trendImgOne}
                        />
                    </Grid>
                    <Grid item md={6} sm={6} xs={12}>
                        <CardItem
                            title="Engineering"
                            listOne="Parametric 3D-modeling"
                            listTwo="Auto-drawing"
                            listThree="Auto-generating calulations"
                            trendImg={trendImgTwo}
                        />
                    </Grid>
                </Grid>
            </div>
        </RootDiv>
    );
}

export default Trend;
