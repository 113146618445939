import { createTheme } from "@mui/material";
import { typography } from "@mui/system";

const theme = createTheme({
    palette: {
        primary: {
            light: "#F5F5F6",
            main: "#EA1C5F",
            gray: "#3D3D3D",
            dark: "#20212C",
        },
        secondary: {
            light: "#F5F5F6",
            main: "#1670B8",
            gray: "#86898B",
            dark: "#2F303F",
        },
        background: {
            black: "#000",
            dark: "#1e1f24",
            white: "#fff",
            footer: "#2c2d36",
        },
        text: {
            primary: "#000",
            secondary: "#fff",
        },
    },
    typography: {
        fontFamily: `'Archivo', sans-serif`,
        fontSize: 16,
        fontWeightThin: 100,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightSemiBold: 600,
        fontWeightBold: 700,
        fontWeightExtraBold: 800,
        h1: {
            fontWeight: typography.fontWeightExtraBold,
            fontSize: "88px",
            lineHeight: "120px",
            letterSpacing: "0em",
        },
        h2: {
            fontWeight: typography.fontWeightExtraBold,
            fontSize: "64px",
            lineHeight: "88px",
            letterSpacing: "0.02em",
        },
        h3: {
            fontWeight: typography.fontWeightThin,
            fontSize: "48px",
            lineHeight: "88px",
            letterSpacing: "0.03em",
        },
        h4: {
            fontWeight: typography.fontWeightThin,
            fontSize: "36px",
            lineHeight: "48px",
            letterSpacing: "0.05em",
        },
        h5: {
            fontWeight: typography.fontWeightRegular,
            fontSize: "18px",
            lineHeight: "20px",
            letterSpacing: "0.07em",
        },
        subtitle1: {
            fontWeight: typography.fontWeightBold,
            fontSize: "48px",
            lineHeight: "52px",
            letterSpacing: "0em",
        },
        subtitle2: {
            fontWeight: typography.fontWeightBold,
            fontSize: "36px",
            lineHeight: "48px",
            letterSpacing: "0em",
        },
        subtitle3: {
            fontWeight: typography.fontWeightSemiBold,
            fontSize: "32px",
            lineHeight: "38px",
            letterSpacing: "0em",
        },
        body1: {
            fontWeight: typography.fontWeightRegular,
            fontSize: "26px",
            lineHeight: "48px",
            letterSpacing: "0em",
        },
        body2: {
            fontWeight: typography.fontWeightMedium,
            fontSize: "21px",
            fontStyle: "italic",
            lineHeight: "30px",
            letterSpacing: "0em",
        },
        body3: {
            fontWeight: typography.fontWeightRegular,
            fontSize: "18px",
            lineHeight: "30px",
            letterSpacing: "0em",
        },
        body4: {
            fontWeight: typography.fontWeightRegular,
            fontSize: "16px",
            lineHeight: "30px",
            letterSpacing: "0.02em",
        },
        body5: {
            fontWeight: typography.fontWeightLight,
            fontSize: "16px",
            fontStyle: "italic",
            lineHeight: "30px",
            letterSpacing: "0.02em",
        },
        button1: {
            fontWeight: typography.fontWeightRegular,
            fontSize: "32px",
            lineHeight: "34px",
            letterSpacing: "0em",
        },
        button2: {
            fontWeight: typography.fontWeightRegular,
            fontSize: "28px",
            lineHeight: "30px",
            letterSpacing: "0em",
        },
        button3: {
            fontWeight: typography.fontWeightBold,
            fontSize: "24px",
            lineHeight: "26px",
            letterSpacing: "0em",
        },
        button4: {
            fontWeight: typography.fontWeightBold,
            fontSize: "21px",
            lineHeight: "23px",
            letterSpacing: "0em",
        },
        button5: {
            fontWeight: typography.fontWeightBold,
            fontSize: "16px",
            lineHeight: "17px",
            letterSpacing: "0.02em",
        },
    },
});

export default theme;
