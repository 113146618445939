import { memoryAddress } from "./ThreeCanvas";
import * as THREE from "three"
import * as THREELINE from "./meshline"
import { extrudeBufferGeometry, rebarGeometry, tubeBufferGeometry } from "./threeModules";
import { lineMaterials, materials } from "./ThreeMaterials";

const sceneAdd = (_mesh) => {
    if (_mesh instanceof THREE.Mesh) memoryAddress.scene.add(_mesh);
}

export function genBeamMesh(b, h) {
    let meshGroup = new THREE.Group()
    if (b !== 0 & h !== 0) {
        const ln = 5
        const cc = 50 * 1e-3

        const Du = 16 * 1e-3
        const nu1 = 3
        const nu2 = 3

        const Dl = 16 * 1e-3
        const nl1 = 3
        const nl2 = 3

        const Dst = 18 * 1e-3
        const sst = 150 * 1e-3

        const Dt = 16 * 1e-3
        const nt = 2

        const d1l = h - cc - Dst - Dl / 2;
        const d2l = d1l - Dl - cc;
        const d1u = cc + Dst + Du / 2;
        const d2u = d1u + Du + cc;

        // Concrete
        const shape = [
            { x: -b / 2, y: -h / 2, z: 0 },
            { x: +b / 2, y: -h / 2, z: 0 },
            { x: +b / 2, y: +h / 2, z: 0 },
            { x: -b / 2, y: +h / 2, z: 0 }
        ]

        let beamGeo = extrudeBufferGeometry(shape, ln)
        let beamMesh = new THREE.Mesh(beamGeo, materials.CONC)
        meshGroup.add(beamMesh)
        // sceneAdd(beamMesh)

        // Upper rebar
        const nLayerU = nu2 !== 0 ? 2 : 1;
        for (let rLayer = 1; rLayer <= 2; rLayer++) {
            let nu = rLayer === 1 ? nu1 : nu2;
            let xur = b / 2 - cc - Dst - Du / 2; // 길이 값(양수)
            let yur = h / 2 - cc - Dst - Du / 2 - (rLayer - 1) * (cc + Du);
            for (let i = 0; i < nu; i++) {
                let sur = nu > 1 ? (2 * xur) / (nu - 1) : 0;
                let positionCoord = { x: -xur + i * sur, y: yur, z: 0 };
                let path = [
                    { x: positionCoord.x, y: positionCoord.y, z: cc },
                    { x: positionCoord.x, y: positionCoord.y, z: ln - cc },
                ];

                let rebarGeo = rebarGeometry(path)
                let rebarMat = lineMaterials(Du, 'rgb(255,0,255)')
                let rebarMesh = new THREE.Mesh(rebarGeo, rebarMat)

                // let rebarGeo = tubeBufferGeometry(path, positionCoord, Du)
                // let rebarMat = customMaterials('rgb(255,255,0)')
                // let rebarMesh = new THREE.Mesh(rebarGeo, rebarMat)
                meshGroup.add(rebarMesh)
                // sceneAdd(rebarMesh)
            }
        }

        // Lower rebar
        const nLayerL = nl2 !== 0 ? 2 : 1;
        for (let rLayer = 1; rLayer <= 2; rLayer++) {
            let nl = rLayer === 1 ? nl1 : nl2;
            let xlr = b / 2 - cc - Dst - Dl / 2; // 길이 값(양수)
            let ylr = h / 2 - cc - Dst - Dl / 2 - (rLayer - 1) * (cc + Dl);
            for (let i = 0; i < nl; i++) {
                let slr = nl > 1 ? (2 * xlr) / (nl - 1) : 0;
                let positionCoord = { x: -xlr + i * slr, y: -ylr, z: 0 };
                let path = [
                    { x: positionCoord.x, y: positionCoord.y, z: cc },
                    { x: positionCoord.x, y: positionCoord.y, z: ln - cc },
                ];

                let rebarGeo = rebarGeometry(path)
                let rebarMat = lineMaterials(Dl, 'rgb(255,0,255)')
                let rebarMesh = new THREE.Mesh(rebarGeo, rebarMat)
                meshGroup.add(rebarMesh)
                // sceneAdd(rebarMesh)
            }
        }

        // Longitudinal rebar for torsion
        const xtr = b / 2 - cc - Dst - Dl / 2;
        const du = nLayerL === 1 ? d1u : d2u;
        const dl = nLayerU === 1 ? d1l : d2l;
        const st = (dl - du) / (nt + 1);
        const ytr = dl - h / 2 - st;
        for (let j = 0; j < 2; j++) {
            for (let i = 0; i < nt; i++) {
                let positionCoord = { x: j === 0 ? -xtr : xtr, y: -ytr + i * st, z: 0 };
                let path = [
                    { x: positionCoord.x, y: positionCoord.y, z: cc },
                    { x: positionCoord.x, y: positionCoord.y, z: ln - cc },
                ];
                let rebarGeo = rebarGeometry(path)
                let rebarMat = lineMaterials(Dt, 'rgb(255,0,255)')
                let rebarMesh = new THREE.Mesh(rebarGeo, rebarMat)
                meshGroup.add(rebarMesh)
                // sceneAdd(rebarMesh)

            }
        }

        // Stirrup
        const lv = ln - 2 * cc - Dst;
        const nst = Math.floor(lv / sst);
        const rem = lv % sst;
        const y0 = cc + Dst / 2;
        let zList = [y0];
        for (let i = 0; i < nst; i++) {
            zList.push(y0 + (rem + sst) / 2 + i * sst);
        }
        zList.push(y0 + lv);

        const xs = b / 2 - cc - Dst / 2;
        const ys = h / 2 - cc - Dst / 2;
        for (let i = 0; i < zList.length; i++) {
            let path = [
                { x: -xs, y: -ys, z: zList[i], },
                { x: -xs, y: ys, z: zList[i], },
                { x: xs, y: ys, z: zList[i], },
                { x: xs, y: -ys, z: zList[i], },
                { x: -xs, y: -ys, z: zList[i], },
            ];
            let rebarGeo = rebarGeometry(path)
            let rebarMat = lineMaterials(Dst, 'rgb(255,255,0)')
            let rebarMesh = new THREE.Mesh(rebarGeo, rebarMat)
            meshGroup.add(rebarMesh)
            // sceneAdd(rebarMesh)
        }
    }
    return meshGroup
}
