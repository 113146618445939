import { styled } from "@mui/material";
import React from "react";
import { images } from "../About";

const SlideNumWrapper = styled("div")(({ theme }) => ({
    position: "absolute",
    bottom: 20,
    right: 40,
    [theme.breakpoints.down("lg")]: {
        bottom: 10,
        right: 20,
    },
    [theme.breakpoints.down("sm")]: {
        display: "none",
    },
    [`& > .slide-current-number`]: {
        fontSize: "24px",
        lineHeight: "26px",
        fontWeight: 200,
        color: theme.palette.text.secondary,
    },
    [`& > .slide-total-number`]: {
        fontSize: "16px",
        lineHeight: "18px",
        fontWeight: 200,
        color: theme.palette.primary.light,
    },
}));

function SlideNumber({ slideIndex }) {
    return (
        <SlideNumWrapper className="slide-number-wrapper">
            <span className="slide-current-number">{slideIndex + 1}</span>
            <span className="slide-total-number"> / {images.length}</span>
        </SlideNumWrapper>
    );
}

export default SlideNumber;
