import React, { useEffect, useState } from "react";
import { Button, styled, Typography, typographyClasses } from "@mui/material";

import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

import PerspectiveGrid from "../../../assets/images/svg/perspective_grid.svg";
import Share from "../../../assets/images/Share.png";
import ShareTablet from "../../../assets/images/ShareTablet.png";
import ShareMobile from "../../../assets/images/ShareMobile.png";
import StoreHeader from "../../../assets/images/svg/StoreHeader.svg";
import StoreHeaderTablet from "../../../assets/images/svg/StoreHeaderTablet.svg";
import StoreHeaderMobile from "../../../assets/images/svg/StoreHeaderMobile.svg";
import StoreSearch from "../../../assets/images/svg/StoreSearch.svg";
import StoreSearchTablet from "../../../assets/images/svg/StoreSearchTablet.svg";
import StoreSearchMobile from "../../../assets/images/svg/StoreSearchMobile.svg";

const RootDiv = styled("div")(({ theme }) => ({
    position: "relative",
    display: "flex",
    width: "100%",
    height: "calc(100vh - 100px)",
    minHeight: "1080px",
    backgroundImage: `url(${PerspectiveGrid})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [theme.breakpoints.down("lg")]: { height: "100vh", minHeight: "1024px" },
    [theme.breakpoints.down("sm")]: { minHeight: "667px" },
    [`& > div.radial-bg`]: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        background:
            "radial-gradient(60.2% 60.2% at 51.04% 58.31%, rgba(30, 31, 36, 0) 27.08%, rgba(30, 31, 36, 0.17) 41.4%, rgba(30, 31, 36, 0) 65.55%, rgba(30, 31, 36, 0.43) 75.62%, #1E1F24 84.32%);",
    },
    [`& > div.contents-container`]: {
        position: "absolute",
        top: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
        [theme.breakpoints.down("lg")]: { justifyContent: "center" },
        [theme.breakpoints.down("sm")]: {
            paddingTop: "74px",
        },
        [`& > div.typo-wrapper`]: {
            width: "100%",
            margin: "0 auto",
            textAlign: "center",
            marginBottom: "40px",
            [`& > .${typographyClasses.root}`]: {
                fontFamily: "'Trap', sans-serif",
                [theme.breakpoints.down("sm")]: {
                    fontSize: "30px",
                    lineHeight: "44px",
                    letterSpacing: 0.6,
                },
            },
        },
        [`& > div.store-container`]: {
            position: "relative",
            maxWidth: "1000px",
            minWidth: "280px",
            width: "100%",
            margin: "0 auto",
            marginBottom: "48px",
            overflow: "hidden",
            backgroundColor: "#333",
            borderRadius: theme.spacing(2),
            border: "1px solid rgba(76, 83, 121, 0.7)",
            boxShadow: "20px 20px 160px rgba(85, 108, 236, 0.5)",
            [theme.breakpoints.down("lg")]: {
                maxWidth: "540px",
                height: "340px",
            },
            [theme.breakpoints.down("sm")]: {
                maxWidth: "280px",
                height: "288px",
                marginBottom: "24px",
            },
            [`& > div.store-header`]: {
                width: "100%",
                height: "48px",
                marginBottom: "32px",
                backgroundImage: `url(${StoreHeader})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                [theme.breakpoints.down("lg")]: {
                    marginBottom: "14px",
                    backgroundImage: `url(${StoreHeaderTablet})`,
                },
                [theme.breakpoints.down("sm")]: {
                    marginBottom: "14px",
                    backgroundImage: `url(${StoreHeaderMobile})`,
                },
            },
            [`& > div.store-search`]: {
                maxWidth: "928px",
                width: "100%",
                height: "280px",
                margin: "0 auto",
                marginBottom: "28px",
                backgroundImage: `url(${StoreSearch})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                [theme.breakpoints.down("lg")]: {
                    maxWidth: "486px",
                    height: "120px",
                    marginBottom: "8px",
                    backgroundImage: `url(${StoreSearchTablet})`,
                },
                [theme.breakpoints.down("sm")]: {
                    maxWidth: "260px",
                    height: "100px",
                    marginBottom: "8px",
                    backgroundImage: `url(${StoreSearchMobile})`,
                },
            },
            [`& > p.new-discovery`]: {
                fontSize: "16px",
                fontWeight: 700,
                lineHeight: "20px",
                letterSpacing: "2px",
                color: theme.palette.text.secondary,
                marginLeft: "35px",
                marginBottom: "24px",
                [theme.breakpoints.down("lg")]: {
                    marginBottom: "8px",
                    marginLeft: "26px",
                },
                [theme.breakpoints.down("sm")]: {
                    marginLeft: "12px",
                    fontSize: "10px",
                    fontWeight: 600,
                    lineHeight: "18px",
                    letterSpacing: "1.8px",
                },
            },
            [`& > div.card-wrapper`]: {
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                padding: "0 35px",
                marginBottom: "50px",
                [theme.breakpoints.down("lg")]: {
                    maxWidth: "486px",
                    width: "100%",
                    padding: "unset",
                    margin: "0 auto",
                },
                [theme.breakpoints.down("sm")]: {
                    justifyContent: "center",
                },
                [`& > div.card-container`]: {
                    maxWidth: "288px",
                    width: "100%",
                    height: "132px",
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "row",
                    borderRadius: "10px",
                    padding: theme.spacing(2),
                    backgroundColor: "#262626",
                    [theme.breakpoints.down("lg")]: {
                        maxWidth: "232px",
                        height: "104px",
                        [`&:last-of-type`]: { display: "none" },
                    },
                    [theme.breakpoints.down("sm")]: {
                        maxWidth: "260px",
                        height: "78px",
                        alignItems: "center",
                        padding: theme.spacing(1),
                        [`&:nth-of-type(2)`]: { display: "none" },
                    },
                    [`& > div.card-icon`]: {
                        width: "96px",
                        height: "96px",
                        borderRadius: "14px",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        background:
                            "linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))",
                        boxShadow:
                            "0px 2.3328px 2.3328px rgba(0, 0, 0, 0.25), inset 2.3328px 2.3328px 2.3328px rgba(146, 146, 146, 0.55)",
                        marginRight: "26px",
                        [theme.breakpoints.down("lg")]: {
                            width: "69px",
                            height: "69px",
                            marginRight: "12px",
                            borderRadius: "10px",
                            backgroundSize: "cover",
                        },
                        [theme.breakpoints.down("sm")]: {
                            width: "56px",
                            height: "56px",
                        },
                    },
                    [`& > div.content-box`]: {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        [`&  > div`]: {
                            [`& > p.title`]: {
                                fontWeight: 500,
                                fontSize: "14px",
                                lineHeight: "20px",
                                letterSpacing: 0.6,
                                color: theme.palette.text.secondary,
                                marginBottom: theme.spacing(1),
                                [theme.breakpoints.down("lg")]: {
                                    marginBottom: 0,
                                },
                                [theme.breakpoints.down("sm")]: {
                                    fontSize: "10px",
                                    lineHeight: "18px",
                                    letterSpacing: 0.54,
                                },
                            },
                            [`& > p.namespace`]: {
                                fontWeight: 400,
                                fontSize: "12px",
                                lineHeight: "20px",
                                letterSpacing: 0.32,
                                color: "#8d8d8d",
                                [theme.breakpoints.down("sm")]: {
                                    fontSize: "9px",
                                    lineHeight: "18px",
                                    letterSpacing: 0.29,
                                },
                            },
                        },
                        [`& > .button`]: {
                            maxWidth: "50px",
                            minWidth: "34px",
                            padding: "2px",
                            borderColor: "#d9d9d9",
                            borderRadius: "6px",
                            [theme.breakpoints.down("sm")]: {
                                width: "34px",
                                height: "16px",
                                borderRadius: "4px",
                            },
                            [`& > .${typographyClasses.root}`]: {
                                textTransform: "none",
                                fontSize: "12px",
                                fontWeight: 500,
                                lineHeight: "16px",
                                letterSpacing: 0.3,
                                color: theme.palette.text.secondary,
                                [theme.breakpoints.down("sm")]: {
                                    fontSize: "8px",
                                },
                            },
                        },
                    },
                },
            },
        },
        [`& > div.share-container`]: {
            position: "absolute",
            top: "50%",
            left: "50%",
            maxWidth: "1179px",
            width: "100%",
            height: "185px",
            transform: "translate(-50%, 0%)",
            backgroundImage: `url(${Share})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            [theme.breakpoints.down("lg")]: {
                maxWidth: "660px",
                height: "173px",
                transform: "translate(-50%, -30%)",
                backgroundImage: `url(${ShareTablet})`,
            },
            [theme.breakpoints.down("sm")]: {
                maxWidth: "338px",
                height: "138px",
                transform: "translate(-50%, 10%)",
                backgroundImage: `url(${ShareMobile})`,
            },
            [`& > img`]: {
                width: "100%",
                height: "100%",
            },
        },
        [`& > .start-button`]: {
            display: "flex",
            maxWidth: "280px",
            width: "100%",
            padding: "8px 0",
            margin: "0 auto",
            borderRadius: "60px",
            fontWeight: 500,
            fontSize: "28px",
            textTransform: "none",
            [theme.breakpoints.down("sm")]: {
                fontSize: "16px",
                fontWeight: 400,
                maxWidth: "180px",
            },
        },
        [`& svg.icon`]: {
            width: "40px",
            display: "flex",
            justifyContent: "center",
            color: "#fff",
            margin: "0 auto",
            marginTop: "30px",
            opacity: 1,
            // animation: "blink-effect 1s step-end infinite",
        },
        // "@keyframes blink-effect": {
        //     "50%": {
        //         opacity: 0,
        //     },
        // },
    },
}));

export function AppCardComponent({ title, namespace, icon, to }) {
    return (
        <div className="card-container">
            <div
                className="card-icon"
                style={{ backgroundImage: `url(${icon})` }}
            />
            <div className="content-box">
                <div>
                    <Typography className="title">{title}</Typography>
                    <Typography className="namespace">{namespace}</Typography>
                </div>
                <Button
                    variant="outlined"
                    className="button"
                    component="a"
                    href={to}
                    target="_blank"
                    rel="noopener"
                    disableTouchRipple
                    disableRipple
                    disableFocusRipple
                    focusRipple>
                    <Typography>Free</Typography>
                </Button>
            </div>
        </div>
    );
}

function Main() {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        const getData = async () => {
            const response = await fetch("/data/project.json");
            const data = await response.json();
            setProjects(data);
        };
        getData();
    }, []);

    return (
        <RootDiv>
            {/* <div className="backgroundimg" /> */}
            <div className="radial-bg" />
            <div className="contents-container">
                <div className="typo-wrapper">
                    <Typography variant="h2" color="#ea1c5f">
                        Create your App
                    </Typography>
                    <Typography variant="h2" color="#b2beff">
                        Build, Deploy, Share
                    </Typography>
                </div>
                <div className="store-container">
                    <div className="store-header" />
                    <div className="store-search" />
                    <Typography className="new-discovery">
                        New Discovery
                    </Typography>
                    <div className="card-wrapper">
                        {projects &&
                            projects.project?.map(item => (
                                <AppCardComponent
                                    key={item.id}
                                    title={item.name}
                                    namespace={item.namespace_name}
                                    icon={item.icon}
                                    to={`https://x.nexivil.com/app/${item.namespace_slug}/${item.name}`}
                                />
                            ))}
                    </div>
                </div>
                <div className="share-container" />
                <Button
                    className="start-button"
                    variant="contained"
                    component="button"
                    disableFocusRipple
                    disableRipple
                    href="https://x.nexivil.com/"
                    target="_blank"
                    rel="noopener">
                    Get Started X
                </Button>
                <KeyboardDoubleArrowDownIcon className="icon" />
            </div>
        </RootDiv>
    );
}

export default Main;
