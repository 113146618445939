import React from "react";
import { ListItem, ListItemAvatar, TextField, Typography, styled } from "@mui/material";

const Root = styled(ListItem)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "row",
}));

const Field = styled(TextField)(({ theme }) => ({
  flex: 1,
  maxWidth: 250,
}));

function Main({ v }) {
    let currentData = v.value[v.view_info.key] || 0;

    function handleOnBlur(e) {
        currentData = +e.target.value;
        v.value[v.view_info.key] = currentData;
    }

    return (
      <Root>
        <ListItemAvatar>
          <Typography variant="overline">{v.display_key}</Typography>
        </ListItemAvatar>
        <Field
          type="number"
          size="small"
          onBlur={handleOnBlur}
          inputProps={{
            style: {textAlign: "right"}
          }}
          onClick={(e) => {
            if (e.screenX === 0) {
              currentData = e.target.value = 123;
              v.value[v.view_info.key] = currentData;
            }
          }}
          // name={formName}
          defaultValue={currentData}
          // disabled={disabled}
        />
      </Root>
    );
}

export default Main;
