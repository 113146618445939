import React, { useEffect, useState } from "react";
import { styled } from "@mui/material";
import { ReactComponent as Arrow } from "../../assets/images/svg/Arrow.svg";

const RootDiv = styled("div")(({ theme }) => ({
    position: "fixed",
    right: 60,
    bottom: 60,
    width: "68px",
    height: "68px",
    padding: "18px 22px",
    borderRadius: "50%",
    backgroundColor: "#50c0ff",
    cursor: "pointer",
    transform: "translateY(y) translateZ(0)",
    transition: "transform .44s ease, boxShadow .44s ease",
    transitionDuration: ".5s",
    trnasitionProperty: "transform",
    [`&:hover`]: {
        boxShadow: "0 8px 8px rgba(0, 0, 0, .25)",
        transform: "translateY(-8px)",
        animationName: "btnUpDown",
        animationDuration: ".2s",
        animationDelay: 1,
        animationTimingFunction: "linear",
        animationIterationCount: 1,
        animationDirection: "alternate",
    },
    [theme.breakpoints.down("lg")]: {
        width: "52px",
        height: "52px",
        padding: "10px 14px",
    },
    [theme.breakpoints.down("sm")]: {
        display: "none",
    },
}));

function ScrollTopButton() {
    const [scrollY, setScrollY] = useState(0);
    const [btnState, setBtnState] = useState(false);

    const handleFollow = () => {
        setScrollY(window.pageYOffset);
        if (scrollY > 100) {
            setBtnState(true);
        } else {
            setBtnState(false);
        }
    };

    const ScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        setScrollY(0);
        setBtnState(false);
    };

    useEffect(() => {
        const watch = () => {
            window.addEventListener("scroll", handleFollow);
        };
        watch();
        return () => {
            window.removeEventListener("scroll", handleFollow);
        };
    });

    return (
        <React.Fragment>
            {btnState && (
                <RootDiv onClick={ScrollToTop}>
                    <Arrow />
                </RootDiv>
            )}
        </React.Fragment>
    );
}

export default ScrollTopButton;
