import React, { useEffect } from "react";
import {
    Box,
    Button,
    Container,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    styled,
    Toolbar,
    Typography,
} from "@mui/material";

import { ReactComponent as CloseIcon } from "../../../assets/images/svg/CloseIcon.svg";
import { ReactComponent as MobileMail } from "../../../assets/images/svg/M_Mail.svg";
import { ReactComponent as MobileMap } from "../../../assets/images/svg/M_Map.svg";
import { ReactComponent as MobilePDF } from "../../../assets/images/svg/M_Pdf.svg";
import { ReactComponent as LogoOrigin } from "../../../assets/images/svg/Logo_Origin.svg";
import { Link } from "react-router-dom";

const ToggleMenu = styled("div")(({ theme }) => ({
    position: "fixed",
    overflow: "hidden",
    touchAction: "none",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 9999,
    backgroundColor: "#fff",
    animationName: "slide-menu",
    animationDuration: "0.5s",
    // animationDelay: 1,
    animationTimingFunction: "linear",
    animationIterationCount: 1,
    animationDirection: "alternate",
    display: "flex",
    flexDirection: "column",
    [`& > .MuiContainer-root`]: {
        flex: 1,
        padding: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflow: "hidden",
        [`& > .header-wrapper`]: {
            width: "100vw",
            height: "100px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingLeft: "20px",
            paddingRight: "20px",
        },
        [`& > div`]: {
            [`& > .mobile-nav-links`]: {
                width: "100%",
                // height: "calc(100vh - 100px)",
                // display: "flex",
                // justifyContent: "start",
                paddingTop: "38px",
                color: theme.palette.primary.dark,
                fontSize: "21px",
                fontWeight: theme.typography.fontWeightSemiBold,
                lineHeight: "32px",
                letterSpacing: "0.03em",
                padding: "0 20px",
                flexGrow: 1,
                [`& > .MuiListItem-root`]: {
                    padding: 0,
                    marginBottom: "15px",
                    [`& > .MuiListItemButton-root, .showcase-link`]: {
                        padding: 0,
                        textDecoration: "none",
                        [`&:hover`]: {
                            color: theme.palette.primary.main,
                            background: "none",
                            transition: "all 0.5s ease-in-out",
                            textDecoration: "none",
                        },
                    },
                },
                [`& > .mobile-start-button`]: {
                    marginTop: "68px",
                    marginBottom: "32px",
                    padding: "11px 22px",
                    fontSize: "18px",
                    fontWeight: theme.typography.fontWeightBold,
                    lineHeight: "20px",
                    letterSpacing: "0.02em",
                    color: theme.palette.primary.dark,
                    border: "1px solid #20212c",
                    borderRadius: "8px",
                    [`&:hover`]: {
                        color: theme.palette.primary.main,
                        background: "none",
                        border: "1px solid #ea1c5f",
                    },
                },
            },
            [`& > .MuiBox-root`]: {
                width: "100vw",
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#1768A9",
                padding: "51px 54px",
                [`& > .iconGroup`]: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    [theme.breakpoints.down("lg")]: {
                        marginBottom: "28px",
                    },
                    [`& > .MuiIconButton-root`]: {
                        width: "36px",
                        height: "36px",
                        backgroundColor: "#fff",
                        borderRadius: "15px",
                        marginRight: "32px",
                        padding: "7px",
                        [`&:last-child`]: {
                            marginRight: 0,
                        },
                    },
                },
                [`& > .slogan`]: {
                    color: theme.palette.text.secondary,
                    fontSize: "14px",
                    fontWeight: theme.typography.fontWeightLight,
                    fontStyle: "italic",
                    lineHeight: "30px",
                    textAlign: "center",
                },
            },
        },
        "@keyframes slide-menu": {
            "0%": {
                position: "fixed",
                bottom: 0,
                left: "100vw",
                opacity: 0,
            },
            "100%": {
                position: "fixed",
                bottom: 0,
                left: 0,
                opacity: 1,
            },
        },
    },
}));

function MobileMenu({ handleToggleMenu, scrollToAbout }) {
    useEffect(() => {
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "unset";
        };
    }, []);

    return (
        <ToggleMenu>
            <Container>
                <div className="header-wrapper">
                    <Link to="/" onClick={handleToggleMenu}>
                        <LogoOrigin />
                    </Link>
                    <IconButton
                        size="large"
                        edge="start"
                        onClick={handleToggleMenu}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        overflow: "hidden auto",
                        width: "100%",
                    }}>
                    <List className="mobile-nav-links">
                        <ListItem disablePadding>
                            <ListItemButton
                                component="a"
                                href="https://x.nexivil.com/"
                                target="_blank"
                                rel="noopener"
                                disableTouchRipple
                                disableRipple
                                disableFocusRipple
                                focusRipple
                                onClick={handleToggleMenu}>
                                DesignExpress
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton
                                component={Link}
                                to="/showcase"
                                className="showcase-link"
                                disableTouchRipple
                                disableRipple
                                disableFocusRipple
                                focusRipple
                                onClick={handleToggleMenu}>
                                Showcase
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <ListItemButton
                                component="a"
                                onClick={scrollToAbout}
                                disableTouchRipple
                                disableRipple
                                disableFocusRipple
                                focusRipple>
                                Company
                            </ListItemButton>
                        </ListItem>
                        <Button
                            variant="outlined"
                            className="mobile-start-button"
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                            focusRipple
                            href="https://passport.nexivil.com"
                            target="_blank"
                            rel="noopener"
                            onClick={handleToggleMenu}>
                            Sign In / Up
                        </Button>
                    </List>
                    <Box>
                        <div className="iconGroup">
                            <IconButton
                                href="https://www.nexivil.com/media/public/dx_promote.pdf"
                                target="_blank"
                                rel="noopener">
                                <MobilePDF />
                            </IconButton>
                            <IconButton href="mailto:support@nexivil.com">
                                <MobileMail />
                            </IconButton>
                            <IconButton
                                href="https://goo.gl/maps/dnXS2Bz1fPyXFRhm9"
                                target="_blank"
                                rel="noopener">
                                <MobileMap />
                            </IconButton>
                        </div>
                        <Typography variant="body3" className="slogan">
                            To the Next Civilization, NEXIVIL
                        </Typography>
                    </Box>
                </div>
            </Container>
        </ToggleMenu>
    );
}

export default MobileMenu;
